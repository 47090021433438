import { Component, OnInit,ViewChild } from '@angular/core';
import { Platform,NavParams, ModalController,NavController,LoadingController} from '@ionic/angular';
import { CurrencyPipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../services/data.service';
import { HttpClient } from '@angular/common/http';
import { EngLaService } from '../../propertiesFile/eng-la.service';
import { FrnLaService } from '../../propertiesFile/frn-la.service';

@Component({
  selector: 'app-juvenile-client-popover',
  templateUrl: './juvenile-client-popover.page.html',
  styleUrls: ['./juvenile-client-popover.page.scss'],
})
export class JuvenileClientPopoverPage implements OnInit {
  gcvrpuind=false;
  cidivind = false;
  gcvrpucidiarr;
  nM;
  juvedetailsjson;
  langOpt;
  dLan;
  dL1;
  rowid;
  insured;
  ownername;
  gcvrpucidi;
  xd24;
  paymentmode;
  gcvrpuoptions='GCVRPU100';
  cidioptions='CIDI100';
  constructor(public loadingController: LoadingController,public platform:Platform,private route: ActivatedRoute,/*private navParams: NavParams,*/ private modalController: ModalController, public currencyPipe: CurrencyPipe, private navCtrl :NavController, public dX684: DataService, private http: HttpClient, private en: EngLaService,private fr: FrnLaService) { 
    this.route.queryParams.subscribe(params => {
      this.kroCheck();
      //const xx = this.navParams.get('alphaBateycaly');
      const xx = this.dX684.getOpenGCVRPUCIDIPopover();
      this.gcvrpucidiarr=xx.px;
      this.gcvrpucidi=xx.value;
      this.xd24 = this.dX684.getData();
      this.nM = xx.nm;
      this.rowid = xx.rowid;
      this.insured=xx.insured;
      this.ownername=xx.ownername;
      this.juvedetailsjson=this.dX684.getJuvenileData();
      if(xx.value==='GCVRPU'){
        this.gcvrpuind=true;
      }
      if(xx.value==='CIDIV'){
        this.cidivind=true;
      }
      this.paymentmode = this.dX684.getPayeeType(this.xd24.freq,this.dL1)
      //console.log("xx.value:"+xx.value+":gcvrpuind:"+this.gcvrpuind+":cidivind:"+this.cidivind);
      //console.log(this.gcvrpucidiarr.doubleArray[34].DArray[this.rowid]);
      
    });
  }

  ngOnInit() {
  }
  closeModal() {
    //this.modalController.dismiss();
    this.navCtrl.navigateForward('juvenile-quote-details');
  }
  
  gcvrupcidiSelect(){
    //console.log("this.gcvrpucidi"+this.gcvrpucidi); 
    if(this.gcvrpucidi==='GCVRPU'){
      this.gcvrpuind=true;
      this.cidivind=false;
    }
    if(this.gcvrpucidi==='CIDIV'){
      this.cidivind=true;
      this.gcvrpuind=false;
    }
  }
  kroCheck() {
    this.langOpt = this.dX684.getLan();
    if (this.langOpt === 'eng') {
      this.dLan = this.en.getQD();
      this.dL1 = this.en.getCinf();
    } else if (this.langOpt === 'frn') {
      this.dLan = this.fr.getQD();
      this.dL1 = this.fr.getCinf();
    }
  }
  cidioptionsselect(){
    console.log(this.cidioptions);
  }
}