import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FrnLaService {
  prefLan;
  menuLan;
  landLan;
  homeLan;
  cInfLan;
  qCLan;
  qDLan;
  constructor() {
   this.setQD();
    this.setQC();
    this.setCinf();
    this.setPrf();
    this.setMenu();
    this.setLand();
    this.setHome();
  }
  getQD(){
    return this.qDLan;
  }
  setQD() {
    this.qDLan = {
      err:{
        p1:'Sélectionnez un plan dans ',
        p2:' Comparaison De Citation',
        p3:' pour voir les détails',
		p4:' Produits disponibles',
		p5:'Sélectionnez un produit parmi ',
	    btn:'Prends-moi là'
      },
      hd:{
        p1:'Sommaire de ',
        p2:'pour'
      },
      aged:'Âge proche',
      agelast:'Dernier âge',
      rof: '&  Résident de',
      pm:' / Mois',
      table:{
        t1: 'Citation',
        t2: 'Classe de Taux',
        t3: 'Produit Type'
      },
      wodRD:{
        p1: 'Aurait',
        p2: ' comme opter pour les coureurs',
        p3: ' souhaitez obtenir une copie du devis'
      },
      btn: ' Recalculer Citation',
      em:'Adresse E-mail',
      txt:'Numéro de portable',
      calculate:' Calculer Citation',
      male:'mâle',
      female:'femelle'
    };
  }
  setQC() {
    this.qCLan = {
      err: {
        p1:'Les détails suivants sont manquants dans',
        p2: ' Informations Client',
        btn:'Prends-moi là'
      },
      hd:'Planifier les options pour',
      insuranceneed:"Besoins d'assurance pour",
      aged:'Âge proche',
      agelast:'Dernier âge',
      rof: '&  Résident de',
      male:'mâle',
      female:'femelle'
    };
  }
  getQC(){
    return this.qCLan;
  }
  setCinf() {
    this.cInfLan = {
      name:'Nom',
      dob: 'Date de Naissance',
      agenearest:'Âge proche',
      agelast:'Dernier âge',
      phone: 'Contact',
      state: 'l\' État',
      juverrormsg:'You are trying to add a duplicate record',
      nameerrormsg: 'Please enter a name',
      sex: {
        genderlabel: 'Le sexe',
        t1: 'Homme',
        t2: 'Femme'
      },
      rc: 'Catégorie de taux',
      pt: {
        title: 'Type de produit',
        t1: 'Médical',
        t2: 'Non-Médical'
      },
      solve: {
        title:'Résoudre pour',
        t1: 'Montant de protection',
        t2: 'Prime mensuelle',
        t5: 'mensuelle',
        t6: 'annuelle',
        t3: 'la prime mensuelle',
        t4: 'le montant de protection',
        t7: 'Fréquence de paiement'
      },
      insuranceneeds: {
        q1:"Combien d'économies?",
        q2:'Montant des investissements?',
        q3:'Montant de la couverture vie existante?',
        q4:'Combien reste-t-il sur votre hypothèque?',
        q5:"Combien devez-vous à d'autres dettes, telles que des cartes de crédit ou des prêts?",
        q6:"Combien voulez-vous consacrer à votre: (y compris les funérailles, l'éducation des enfants et les événements majeurs de la vie)?",
        q7:'Quel est votre revenu annuel après impôt?',
        q8:'De quel pourcentage de revenu votre famille aura-t-elle besoin?',
        q9:"Pendant combien d'années votre famille aura-t-elle besoin d'un soutien financier?",
        labelscreen1:'Vos atouts',
        labelscreen2:'Vos dépenses',
        labelscreen3:'Un revenu pour votre famille',
        calculatebutton:'Calculer',
        yourpersonalinfo: 'Vos informations personnelles sont:',
        proceedbutton: 'Poursuivre avec les options',
        validateyourinformation: 'Validez vos informations',
        getquotebutton: 'Obtenir un devis',
        productselection:'Go to Product Selection',
        editbutton: 'Éditer',
        cancelbutton: 'Annuler',
        savebutton:'Save',
        company: 'Foresters Financial',
        insurancecalclabel:"Calculateur d'assurance",
        totalrequirement:'Assurance vie totale nécessaire:',
        existingcoverage:"Couverture d'assurance vie existante:",
        addnlrequirement:'Assurance vie supplémentaire nécessaire:',
        alertmsg: "Si vous souhaitez modifier vos informations personnelles, <br\>cliquez sur «Modifier» ou pour obtenir un devis d'assurance, cliquez sur «Obtenir un devis»"
      },
      eApp: {
        q1:'Numéro de sécurité sociale:',
        q2:"Ligne d'adresse 1:",
        q3:"Ligne d'adresse 2:",
        q4:'Ville:',
        q5:'Etat:',
        q6:'Code postal:',
        q7:'Numéro de téléphone:',
        q8:'Meilleur moment pour appeler:',
        q9:'Email:',
      },
      landingpage:{
        l1:'Bienvenue',
        l2:'Aidez-moi',
        l3:"Pour l'assistance App:",
        l4:"Ligne de support d'application:",
        l5:'Ressources',
        l6: 'Cliquez',
        l7: 'ici',
        l8: 'pour aller à',
        l9:'Obtenez votre devis',
        l10:'Dernières nouvelles',
        l11:'service@truenorth.com',
        l12: 'Truenorth.com',
        l13: 'Truenorth.iidemo.com',
        l14: 'Bonjour',
        l15: 'Bonne après-midi',
        l16: 'Bonsoir',
        l17: 'Foresters'
      },
      knowyourproductpage:{
        k1:'CONNAISSEZ VOS PRODUITS',
        k2:'Obtenez votre devis',
        k3: 'En savoir plus sur les produits disponibles'
      },
      reiderslabel:{
        q1: 'Mort accidentelle',
        q2: 'Terme enfant',
        q3: 'Exonération de prime',
        q4: 'Assurabilité garantie',
        q5: "Option d'achat garantie",
        q6: 'Exonération de la déduction mensuelle',
        q7: 'Durée 10',
        q8: 'Durée 20',
        q9: 'wpr',
        q10:'Disability Income:',
        q11:'Return of Premium:',
        q12:'Sub Rating:'
      },
      resourcespage:{
        r1: 'RESSOURCES',
        r2: 'Guide de souscription',
        r3: 'Matériel de vente',
        r4: 'Trouvez vos guides de référence utiles'
      },
      quotecomparepage:{
        p1: 'Advantage Plus II',
        p2: 'PlanRight',
        p3: 'SMART UL',
        p4: 'Your Term',
        p5: 'Strong Foundation',
        p6: 'BrightFuture',
        p7:'Prepared Accidental Death',
        pdefault: 'Term'
      },
      producttype:{
        med: 'Médical',
        nonmed:'Non-Médical',
        notapplicable:"NA"
      },
      pagetitle:{
        p1: 'INFORMATIONS CLIENT',
        p2: 'COMPARER DES PLANS',
        p3: 'PRODUITS DISPONIBLES',
        p4: 'DÉTAILS DU PLAN',
        p5: 'DÉTAILS DU PRODUIT',
        p6: 'DEVIS DISPONIBLES',
        p7: 'ACCUEIL',
        p8: "BESOINS D'ASSURANCE",
        p9: "RENSEIGNEMENTS PERSONNELS",
        p10: "JUVENILE CLIENT ENTRY",
        p11: "GCV RPU & CI DIVIDEND REPORT",
        p12: "JUVENILE QUOTE DETAILS"
        //p13: "ADMINISTRATION"
      },
      amount:'Montant',
      monthlypremmode:'Prime mensuelle',
      annualpremmode: 'Premium annuel',
      monthly:'mensuelle',
      paymentmode:'Mode de paiement',
      quarterly:'Trimestrielle',
      semiannuallry: 'semestriellement',
      annually: 'Annuelle',
      selectaproduct: 'Choisissez un produit',
      quotecompare: 'Comparaison de devis'
    }
  }
  getCinf() {
    return this.cInfLan;
  }
  setHome() {
    this.homeLan = {
      t1: 'Client',
      t2: 'Produits disponibles',
      t3: 'Personnaliser',
      t4: 'Login',
      t5: 'Besoin assurance',
      t6: 'Compare devis',
      t7: 'Cavalière',
      t8: 'Candidats'
    };
  }
  
  getHome() {
    return this.homeLan;
  }
  setPrf() {
    this.prefLan = {
      title: 'PREFERENCES',
      langLab: 'Langue',
      Defaultpagelabel: 'Page de destination',
      Defaultpageopt1: 'Bienvenue',
      Defaultpageopt2: 'Client',
      Defaultpageopt3:'Product',
      Defaultpageopt4:'A Specific Product',
      Productoption:'Select a Product',
      savepreference: 'Enregistrer la préférences',
      Defaultstatelabel: 'État préféré',
      PrefName:'Préféré Nom',
      prefissueage: 'Prefered Age of Client',
      prefcoverage: 'Preferred Coverage',
      Prefcolorlabel:'Schéma de couleur',
      Quotepref :'Generate Special Quote',
      blue_theme:'Bleu',
      black_theme:'Noir',
      purple_theme:'Violet',
      green_theme:'Vert',
      Quoteprefyes:'Yes',
      Quoteprefno: 'No'
    }
  }
  getPrf() {
    return this.prefLan;
  }

  setMenu() {
    this.menuLan = {
      t1: 'Domicile',
      t2: 'Combien d\'Assurance',
      t3: 'Ressources',
      t4: 'Connaissez Votre Produit',
      t5: 'TéléchargerEzbiz',
      t6: 'Préférences',
      t7: 'Obtenir un devis',
      t8: 'Product Selection',
      t9: 'Quote Compare'
      //t10: "Administration"
    }
  }
  getMenu() {
    return this.menuLan;
  }
  setLand() {
    this.landLan = {
      title: 'Financière Foresters',
      msg: 'Quelques Messages .... Pour..Agents ..'
    };
  }
  getLand() {
    return this.landLan;
  }
  getProductDescription(productname){
    if(productname==='Advantage Plus II'){
      return 'Ce produit est un produit Whole Life idéal pour les clients de 18 à 40 ans';
    }
    if(productname==='SMART UL'){
      return 'Ce produit est un produit Universal Life idéal pour les clients de 18 à 55 ans';
    }
    if(productname==='Your Term'){
      return 'Ce produit est un produit à terme idéal pour les clients de 18 à 65 ans';
    }
    if(productname==='PlanRight'){
      return 'Ce produit est un produit Whole Life idéal pour les clients de 55 à 80 ans';
    }
    if(productname==='Strong Foundation'){
      return 'Ce produit est un produit à terme de base idéal pour les clients de 18 à 65 ans';
    }
    if(productname==='BrightFuture'){
      return 'Ce produit est un produit à terme de base idéal pour les clients de 18 à 65 ans';
    }
    if(productname==='Prepared Accidental Death'){
      return 'This Term Life product is for clients from age 18-60. State variations may apply. Please refer to the product brochure for more details';
    }
  }
}