import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class StateWithCodesService {
  constructor(private route: ActivatedRoute, private router: Router) {
    this.route.queryParams.subscribe(params => {
        //console.log(this.router.getCurrentNavigation())
        if(this.router.getCurrentNavigation()!=null){
            if (this.router.getCurrentNavigation().extras.state) {
                this.states = this.router.getCurrentNavigation().extras.state.city;
            }
        }
      });
   }

 // States Are Here

 private states = [
    {
        code: 'Alaska',
        value: 1,
        text: 'AK'
    },
    {
        code: 'Alabama',
        value: 2,
        text: 'AL'
    },
    {
        code: 'Arkansas',
        value: 3,
        text: 'AR'
    },
    {
        code: 'Arizona',
        value: 4,
        text: 'AZ'
    },
    {
        code: 'California',
        value: 5,
        text: 'CA'
    },
    {
        code: 'Colorado',
        value: 6,
        text: 'CO'
    },
    {
        code: 'Connecticut',
        value: 7,
        text: 'CT'
    },
    {
        code: 'District of Columbia',
        value: 8,
        text: 'DC'
    },
    {
        code: 'Delaware',
        value: 9,
        text: 'DE'
    },
    {
        code: 'Florida',
        value: 10,
        text: 'FL'
    },
    {
        code: 'Georgia',
        value: 11,
        text: 'GA'
    },
    {
        code: 'Hawaii',
        value: 12,
        text: 'HI'
    },
    {
        code: 'Iowa',
        value: 13,
        text: 'IA'
    },
    {
        code: 'Idaho',
        value: 14,
        text: 'ID'
    },
    {
        code: 'Illinois',
        value: 15,
        text: 'IL'
    },
    {
        code: 'Indiana',
        value: 16,
        text: 'IN'
    },
    {
        code: 'Kansas',
        value: 17,
        text: 'KS'
    },
    {
        code: 'Kentucky',
        value: 18,
        text: 'KY'
    },
    {
        code: 'Louisiana',
        value: 19,
        text: 'LA'
    },
    {
        code: 'Massachusetts',
        value: 20,
        text: 'MA'
    },
    {
        code: 'Maryland',
        value: 21,
        text: 'MD'
    },
    {
        code: 'Maine',
        value: 22,
        text: 'ME'
    },
    {
        code: 'Michigan',
        value: 23,
        text: 'MI'
    },
    {
        code: 'Minnesota',
        value: 24,
        text: 'MN'
    },
    {
        code: 'Missouri',
        value: 25,
        text: 'MO'
    },
    {
        code: 'Mississippi',
        value: 26,
        text: 'MS'
    },
    {
        code: 'Montana',
        value: 27,
        text: 'MT'
    },
    {
        code: 'North Carolina',
        value: 28,
        text: 'NC'
    },
    {
        code: 'North Dakota',
        value: 29,
        text: 'ND'
    },
    {
        code: 'Nebraska',
        value: 30,
        text: 'NE'
    },
    {
        code: 'New Hampshire',
        value: 31,
        text: 'NH'
    },
    {
        code: 'New Jersey',
        value: 32,
        text: 'NJ'
    },
    {
        code: 'New Mexico',
        value: 33,
        text: 'NM'
    },
    {
        code: 'Nevada',
        value: 34,
        text: 'NV'
    },
    /*{
        code: 'New York',
        value: 35,
        text: 'NY'
    },*/
    {
        code: 'Ohio',
        value: 36,
        text: 'OH'
    },
    {
        code: 'Oklahoma',
        value: 37,
        text: 'OK'
    },
    {
        code: 'Oregon',
        value: 38,
        text: 'OR'
    },
    {
        code: 'Pennsylvania',
        value: 39,
        text: 'PA'
    },
    {
        code: 'Rhode Island',
        value: 40,
        text: 'RI'
    },
    {
        code: 'South Carolina',
        value: 41,
        text: 'SC'
    },
    {
        code: 'South Dakota',
        value: 42,
        text: 'SD'
    },
    {
        code: 'Tennessee',
        value: 43,
        text: 'TN'
    },
    {
        code: 'Texas',
        value: 44,
        text: 'TX'
    },
    {
        code: 'Utah',
        value: 45,
        text: 'UT'
    },
	{
        code: 'Virginia',
        value: 46,
        text: 'VA'
    },
    {
        code: 'Vermont',
        value: 47,
        text: 'VT'
    },
    {
        code: 'Washington',
        value: 48,
        text: 'WA'
    },
	{
        code: 'Wisconsin',
        value: 49,
        text: 'WI'
    },
    {
        code: 'West Virginia',
        value: 50,
        text: 'WV'
    },
    {
        code: 'Wyoming',
        value: 51,
        text: 'WY'
    }
  ];

// End Of States


  getStates() {
    return this.states;
  }
}
