import { NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule} from '@angular/forms';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { Contacts } from '@ionic-native/contacts/ngx';
import { SMS } from '@ionic-native/sms/ngx';
import { RateClassPopoverPageModule } from './addional/rate-class-popover/rate-class-popover.module';
import { ProductTypePopoverPageModule } from './addional/product-type-popover/product-type-popover.module';
import { AlterPopoverPageModule } from './addional/alter-popover/alter-popover.module';
import { ModalPageModule } from './addional/modal/modal.module';
import { ModalQCOnlyProductPageModule } from './addional/modal-qconly-product/modal-qconly-product.module';
import {GcvrupCidiPopoverPageModule} from './addional/gcvrup-cidi-popover/gcvrup-cidi-popover.module';
import {JuvenileClientPopoverPageModule} from './addional/juvenile-client-popover/juvenile-client-popover.module';
import {PaymentModePageModule} from './addional/payment-mode/payment-mode.module';
import {JuvenileQuoteDetailsPageModule} from './juvenile-quote-details/juvenile-quote-details.module';
import {JuvenileClientInputPageModule} from './juvenile-client-input/juvenile-client-input.module';
import { DataService } from './services/data.service';
import { ClientEditPageModule } from './addional/client-edit/client-edit.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';

import { CurrencyPipe, DatePipe } from '@angular/common';
import { PayeeTypePopoverPageModule } from './addional/payee-type-popover/payee-type-popover.module';
import { ErrorPopoverPageModule } from './addional/error-popover/error-popover.module';
import { IonicStorageModule } from '@ionic/storage';
import { Network } from '@ionic-native/network/ngx';
import { Platform } from '@ionic/angular';
import { SQLitePorter } from '@ionic-native/sqlite-porter/ngx';
import { SQLite } from '@ionic-native/sqlite/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { BrowserTab } from '@ionic-native/browser-tab/ngx';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AppLauncher, AppLauncherOptions } from '@ionic-native/app-launcher/ngx';
import { AppAvailability } from '@ionic-native/app-availability/ngx';

import { AngularFireModule } from '@angular/fire';
import { firebaseConfig } from '../firebase-config';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';


/*import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY'
  },
};*/
const routes: Routes = [
{
path: '',
redirectTo: 'login',
pathMatch: 'full'
},
{
path: 'login',
loadChildren: './login/login.module#LoginPageModule'
}
];

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [RouterModule.forRoot(routes),BrowserModule,IonicModule.forRoot(), AppRoutingModule, ReactiveFormsModule, RateClassPopoverPageModule, AlterPopoverPageModule,ProductTypePopoverPageModule, PayeeTypePopoverPageModule,ModalQCOnlyProductPageModule,ModalPageModule, ClientEditPageModule,GcvrupCidiPopoverPageModule,JuvenileClientInputPageModule, PaymentModePageModule,JuvenileClientPopoverPageModule,JuvenileQuoteDetailsPageModule,ErrorPopoverPageModule,HttpClientModule, /*MatFormFieldModule, MomentDateModule, MatDatepickerModule, MatInputModule, MatNativeDateModule,*/IonicStorageModule.forRoot({name:'forestersquickquotewebdb', driverOrder: ['sqlite','indexeddb','websql']}), ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  AngularFireModule.initializeApp(firebaseConfig), AngularFireAnalyticsModule],
  providers: [Platform,Keyboard,Network,StatusBar,FingerprintAIO,SplashScreen,AppLauncher, AppAvailability,{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, /*{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },*/DataService, DatePipe, CurrencyPipe, Contacts, CallNumber, SMS ,HTTP,HttpClient, SQLite,SQLitePorter, /*InAppBrowser, */BrowserTab],
  bootstrap: [AppComponent],
  
})
export class AppModule {
  
}
