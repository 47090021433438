import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NavController, PopoverController, LoadingController, ToastController, ModalController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../services/data.service';
//import { RateClassPopoverPage } from '../addional/rate-class-popover/rate-class-popover.page';
//import {JuvenileClientPopoverPage} from '../addional/juvenile-client-popover/juvenile-client-popover.page';
//import { ProductTypePopoverPage } from '../addional/product-type-popover/product-type-popover.page';
import { EngLaService } from '../propertiesFile/eng-la.service';
import { FrnLaService } from '../propertiesFile/frn-la.service';
import { CurrencyPipe, JsonPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-juvenile-client-input',
  templateUrl: './juvenile-client-input.page.html',
  styleUrls: ['./juvenile-client-input.page.scss'],
})
export class JuvenileClientInputPage implements OnInit {
  langOpt;
  dLan;
  dL1;
  cName;
  gen;
  age;
  solveAmount;
  juvenilejsonarr;
  city;
  solve;
  xd24;
  rowid;
  snolist;
  disableind=false;
  constructor(public loadingController: LoadingController,
    private route: ActivatedRoute, 
    //private storage: Storage,
    //private mdlPkPl:ModalController,
    public currencyPipe: CurrencyPipe, 
    private navCtrl :NavController, 
    public dX684: DataService, 
    private en: EngLaService,
    private http: HttpClient,
    private fr: FrnLaService) { 
    this.route.queryParams.subscribe(params => {
      this.kroCheck();
      this.rowid=0;
      this.xd24 = this.dX684.getData();
      console.log("line 44:"+this.xd24);
      //this.snolist.push(1);
      //this.cName.push(this.xd24.cName);
      //this.gen.push(this.xd24.gender);
      //this.age.push(this.dX684.calcAge(this.xd24.dob));
      /*this.juvenilejsonarr={
        snolist:this.snolist,
        cNameList:this.cName,
        genList:this.gen,
        ageList:this.age,
        solveAmountList:this.solveAmount
      }*/
      console.log("this.xd24:"+this.xd24+"dX684.getGender("+this.dX684.getGender(this.dLan,this.gen));
    });
  }

  ngOnInit() {
  }
 
  goLanding() {
    this.navCtrl.navigateRoot('/home/landing');
    //this.closeModal();
  }
  /*
  async openJuvenilePopover(name,age,gender,solveamount, operation){
    const dXT62 = {
      name: name,
      age:age,
      gender:gender,
      solveamount:solveamount,
      operation:operation
     };
    //if(this.cidichk===false||this.gcvrpuchk===false){
      const modal = await this.mdlPkPl.create({
        component: JuvenileClientPopoverPage,
        componentProps: {
          alphaBateycaly: dXT62,
          juvenilejsonarr: this.juvenilejsonarr
        }
      });
      modal.present();
  }*/
  addRow(){
    this.rowid+=1;
    this.snolist.push(this.rowid);
    if(this.rowid==6){
      this.disableind=true;
    }
  }
  kroCheck() {
    this.langOpt = this.dX684.getLan();
    if (this.langOpt === 'eng') {
      this.dLan = this.en.getQD();
      this.dL1 = this.en.getCinf();
    } else if (this.langOpt === 'frn') {
      this.dLan = this.fr.getQD();
      this.dL1 = this.fr.getCinf();
    }
  }
}
