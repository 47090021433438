import { Component, OnInit, Testability } from '@angular/core';
import { NavParams, ModalController, AlertController,NavController, PopoverController } from '@ionic/angular';
import { AlterPopoverPage } from '../../addional/alter-popover/alter-popover.page';
import { ActivatedRoute } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import { DataService } from '../../services/data.service';
import { EngLaService } from '../../propertiesFile/eng-la.service';
import { FrnLaService } from '../../propertiesFile/frn-la.service';
import{JuvenileAgeService} from '../../propertiesFile/juvenile-age.service';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
//import { Agent } from 'http';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.page.html',
  styleUrls: ['./modal.page.scss'],
})
export class ModalPage implements OnInit {
  nddd;
  insExist;
  total;
  totalTemp;
  msg;
  dataservicedata
  solveamount;
  age;
  msgshowquote;
  langOpt;
  d_L32;
  dataLan1;
  data;
  rate;
  PageRender;
  alertmsg;
  constructor(
    private navCtrl: NavController,
    private alertController: AlertController,
    private popoverController: PopoverController,
    private route: ActivatedRoute,
    private navParams: NavParams, 
    private modalController: ModalController, 
    private currencyPipe: CurrencyPipe, 
    private en: EngLaService,
    private fr: FrnLaService,
    private juvAge: JuvenileAgeService,
    public dataService: DataService) {
      this.route.queryParams.subscribe(params => {
        this.kroCheck();
        this.data = this.dataService.getData();
        if(this.data.dob){
          this.age = this.dataService.calcAge(this.data.dob);
        }
        this.rate = this.dataService.GetRateClassName(this.data.rateClass);
        if (this.data.dob && this.data.city) {
          this.PageRender = {
            cName: this.data.cName,
            age: this.age,
            city: this.data.city,
            gender:this.data.gender,
          };
        }
       });

    this.insExist = this.navParams.get('existing');
    this.total = this.navParams.get('total');
    if(this.total>this.insExist)
    {
      this.msgshowquote="True";
    }
    this.dataservicedata = this.dataService.getData();
    this.totalTemp = this.currencyPipe.transform(this.total, 'USD', 'symbol', '1.0-0');
    if(this.insExist === this.total) {
      this.msg = this.navParams.get('needed');
    }else if(this.insExist !== this.total) {
      if(typeof this.navParams.get('needed') === 'number'){
        this.nddd = this.currencyPipe.transform(this.navParams.get('needed'), 'USD', 'symbol', '1.0-0');
      }else{
        this.nddd = this.navParams.get('needed');
      }
    }
    //console.log('Msg:::' + this.msg);
    if(!this.msg){
      this.total = this.currencyPipe.transform(this.total, 'USD', 'symbol', '1.0-0');
      this.insExist  = this.currencyPipe.transform(this.insExist, 'USD', 'symbol', '1.0-0');}
  }

  ngOnInit() {
  }
  kroCheck() {
    this.langOpt = this.dataService.getLan();
   // console.log("in krocheck of How much ins:"+this.langOpt);
    if (this.langOpt === 'eng') {
      this.d_L32 = this.en.getQC();
      this.dataLan1 = this.en.getCinf();
    } else if (this.langOpt === 'frn') {
      this.d_L32 = this.fr.getQC();
      this.dataLan1 = this.fr.getCinf();
    }
  }
  async presentAlertMultipleButtons() {
     this.alertmsg = '<b>'+this.dataLan1.insuranceneeds.yourpersonalinfo+'</b>' +'<br\><br\>'+ this.dataLan1.name+': '+this.PageRender.cName+'<br\>';
     this.alertmsg = this.alertmsg + "Age Nearest: "+this.PageRender.age+'<br\>';
     this.alertmsg = this.alertmsg + "Age Last: "+this.dataService.calcLastAge(this.data.dob)+'<br\>';
     this.alertmsg = this.alertmsg  + this.dataLan1.sex.genderlabel+": "+this.PageRender.gender+'<br\>';
     this.alertmsg = this.alertmsg  + this.dataLan1.state+": "+ this.PageRender.city+'<br\>';
     this.alertmsg = this.alertmsg  + "Rate Class: "+this.rate+'<br\><br\>';
     if(Number(this.dataservicedata.prodTyp) === 0){
      if(this.age>=this.juvAge.getFace56().age && this.navParams.get('needed')>250000){
        this.alertmsg = this.alertmsg  + "<p style='color:var(--ion-color-primary-shade);'\>For non-med coverage, quote will use maximum value of $250,000 for the available products. To quote the full insurance needed, please switch the product type to medical and re-enter the insurance needs calculator</p\><br\><br\>"
      }
      if((this.age>this.juvAge.getJA() && this.age<this.juvAge.getFace56().age) && this.navParams.get('needed')>500000){
        this.alertmsg = this.alertmsg  + "<p style='color:var(--ion-color-primary-shade);'\>For non-med coverage, quote will use maximum value of $500,000 for the available products. To quote the full insurance needed, please switch the product type to medical and re-enter the insurance needs calculator.</p\><br\><br\>"
      }
      if(this.age<=this.juvAge.getJA() && this.navParams.get('needed')>150000){
        this.alertmsg = this.alertmsg  + "<p style='color:var(--ion-color-primary-shade);'\>For non-med coverage, quote will use maximum value of $150,000 for the available products. To quote the full insurance needed, please switch the product type to medical and re-enter the insurance needs calculator</p\><br\><br\>"
      }
    }

     this.alertmsg = this.alertmsg  +this.dataLan1.insuranceneeds.alertmsg;
     if(this.dataService.getPlatform()==="android"){
      const alert = await this.alertController.create({
        //header: this.dataLan1.insuranceneeds.validateyourinformation,
        header: '',
        subHeader: '',
        message: this.alertmsg,
        cssClass:'alertCustomCss',
        buttons: [{text:this.dataLan1.insuranceneeds.editbutton, handler:(clientinfo)=> this.Nav('client-basic-input')}, {text:this.dataLan1.insuranceneeds.getquotebutton, handler:(quotecompare)=> this.Nav('quote-compare')}, {text:this.dataLan1.insuranceneeds.cancelbutton, role: 'cancel'}]
      });
      await alert.present();
    }
    else{
      const popover = await this.popoverController.create({
        component: AlterPopoverPage,
        componentProps: {
          custom_id: "howmuchinsurance",
          dispmsg:this.alertmsg,
        },
      });
      popover.present();
      popover.onDidDismiss().then(() => {
        if(this.dataService.getNavPath()){
          this.closeModal();
          this.navCtrl.navigateRoot(this.dataService.getNavPath());
        }
      });
    }
  }
  Nav(navParam){
    //console.log("hello here");
    this.closeModal();
    this.navCtrl.navigateRoot(navParam);
  }
  closeModal() {
    //this.presentAlertMultipleButtons();
    this.solveamount = this.dataservicedata.amount;
    if(!this.dataservicedata.cName){
        this.dataservicedata.cName='Client';
    }
	  this.dataservicedata.solve='prem';
      //console.log("this.dataservicedata.cName:"+this.dataservicedata.cName);
    this.dataService.setData(this.dataservicedata);
    if(Number(this.dataservicedata.prodTyp) === 0){
      //console.log("line 148");
      if((this.age>this.juvAge.getJA() && this.age<this.juvAge.getFace56().age) &&this.navParams.get('needed')>500000){
        //console.log("line 150");
        this.solveamount = 500000;
        this.dataservicedata.amount = this.solveamount;
        this.dataService.setData(this.dataservicedata);
        this.dataService.setInpputDolr(true);
      }
      else if(this.age<=this.juvAge.getJA() && this.navParams.get('needed')>150000){
        this.solveamount = 150000;
        this.dataservicedata.amount = this.solveamount;
        this.dataService.setData(this.dataservicedata);
        this.dataService.setInpputDolr(true);
      }
      else if(this.age>=this.juvAge.getFace56().age && this.navParams.get('needed')>250000){
        this.solveamount = 250000;
        this.dataservicedata.amount = this.solveamount;
        this.dataService.setData(this.dataservicedata);
        this.dataService.setInpputDolr(true);
      }
      else{
        //console.log("in side else:"+this.nddd);
        this.dataservicedata.amount = this.navParams.get('needed');
        this.dataService.setData(this.dataservicedata);
        this.dataService.setInpputDolr(true);
      }
    }
    if(Number(this.dataservicedata.prodTyp) === 1){
      //console.log("line 164");
      if(this.navParams.get('needed')>10000000){
        //console.log("line 166");
        this.solveamount = 10000000;
        this.dataservicedata.amount = this.solveamount;
        this.dataService.setData(this.dataservicedata);
        this.dataService.setInpputDolr(true);
      }
      else{
        //console.log("in side else:"+this.nddd);
        this.dataservicedata.amount = this.navParams.get('needed');
        this.dataService.setData(this.dataservicedata);
        this.dataService.setInpputDolr(true);
      }
    }
    
    this.modalController.dismiss();
  }
  goLanding() {
    this.navCtrl.navigateRoot('landing');
  }
}