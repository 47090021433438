import { Component, OnInit, ViewChild } from "@angular/core";
import {
  AnalyticsService,
  AnalyticsEventNames,
} from "../../propertiesFile/analytics.service";
import {
  PickerController,
  NavParams,
  LoadingController,
  NavController,
  ModalController,
} from "@ionic/angular";
import { DataService } from "../../services/data.service";
import { Storage } from "@ionic/storage";
import { ActivatedRoute } from "@angular/router";
import { EngLaService } from "../../propertiesFile/eng-la.service";
import { FrnLaService } from "../../propertiesFile/frn-la.service";
import { CurrencyPipe, DatePipe } from "@angular/common";
import { StateWithCodesService } from "../../propertiesFile/state-with-codes.service";
import { JuvenileAgeService } from "../../propertiesFile/juvenile-age.service";
import { HttpClient } from "@angular/common/http";
import { animationFrameScheduler } from "rxjs";
/*import { MAT_DATE_FORMATS } from '@angular/material/core';
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY'
  },
};*/
@Component({
  selector: "app-client-edit",
  templateUrl: "./client-edit.page.html",
  styleUrls: ["./client-edit.page.scss"],
})
export class ClientEditPage implements OnInit {
  dispmsg = "test";
  alertind;
  lO;
  dataLan;
  dL;
  dataServiceData;
  dob;
  doberr;
  dobFlag;
  birthnearestAge: number;
  birthnearestAgeerr = "";
  birthlastAge: number;
  birthlastAgeerr = "";
  state = "";
  stateerr = "";
  stateValue = "";
  cName: string = "";
  cNameerr: string = "";
  solvee;
  gen;
  amountTemp;
  amount;
  amounterr = "";
  solvelabel;
  dolrLength = 6;
  amountFlag = false;
  stateFlag = false;
  prodTyp;
  rateClass;
  rateClassList;
  freq;
  productname;
  minYearDisplay;
  stateList = [];
  stateValueList = [];
  src = "";
  today;
  dataServiceDataOrig;
  dobtemp;
  blureffect = true;
  channelcode;
  px62;
  prod;
  subchannelcode;
  producterr;
  @ViewChild("dobinput", { static: false }) dobinput;
  @ViewChild("nameInput", { static: true }) nameInput;
  constructor(
    private navParams: NavParams,
    //private navCtrl:NavController,
    private modalController: ModalController,
    public loadingController: LoadingController,
    public ds: DataService,
    private en: EngLaService,
    private juv: JuvenileAgeService,
    private route: ActivatedRoute,
    private usState: StateWithCodesService,
    private currencyPipe: CurrencyPipe,
    private datepipe: DatePipe,
    private storage: Storage,
    private http: HttpClient,
    private analyticsService: AnalyticsService,
    private fr: FrnLaService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.alertind = this.navParams.get("custom_id");
      this.productname = this.navParams.get("product");
      //console.log("alertind:"+this.alertind + "productname:"+ this.productname);
      this.src = "../assets/icon/foresters-financial-logo.svg";
      this.kroCheck();
      this.dataServiceData = this.ds.getData();
      this.cName = this.dataServiceData.cName;
      this.dob = this.dataServiceData.dob;
      this.dobtemp = this.dataServiceData.dob;
      this.gen = this.dataServiceData.gender;
      this.state = this.dataServiceData.city;
      this.stateValue = this.dataServiceData.stateValue;
      this.solvee = this.dataServiceData.solve;
      this.amount = this.dataServiceData.amount;
      this.freq = this.dataServiceData.freq;
      this.solvelabel = this.dataServiceData.solvelabel;
      this.rateClass = Number(this.dataServiceData.rateClass);
      this.prodTyp = this.dataServiceData.prodTyp;
      this.rateClassList = this.dataServiceData.rateClassList;
      this.load();
      //this.ageValue();
      this.dollar();
      this.loaddob(this.dobtemp);
      this.formatdate();
      console.log(this.productname);
      if (
        this.productname == "Strong Foundation" ||
        this.productname == "PlanRight"
      ) {
        this.prodTyp = "0";
        console.log(this.prodTyp);
      }
      if (this.productname == "Your Term – Med, Non-Med Term") {
        //this.prodTyp = "1";
        //console.log(this.prodTyp);
      }
    });
  }
  ngOnInit() {}
  ngAfterViewInit() {
    setTimeout(() => {
      this.nameInput.setFocus();
    }, 500);
  }
  loaddob(dateofbirth) {
    this.birthlastAge = this.ds.calcLastAge(dateofbirth.slice(0, 10));
    this.birthnearestAge = this.ds.calcAge(dateofbirth.slice(0, 10));
  }
  async load() {
    const loading = await this.loadingController.create({
      message: "Loading",
      //duration: 100,
    });
    await loading.present();
    loading.dismiss();
  }
  showPicker() {
    let selectedindex = this.stateValueList.indexOf(Number(this.stateValue));
    this.state = this.stateList[selectedindex].text;
    this.stateFlag = true;
    this.ds.setInpputSt(this.stateFlag);
    //this.x();
  }

  getThatcName() {
    if (this.cName === null) {
      this.cName = "Valued Client";
    }
  }
  handleEnter(e) {
    e.target.blur();
  }
  checkAgeChange() {
    if (
      this.birthnearestAge == this.ds.calcAge(this.dobtemp.slice(0, 10)) &&
      this.birthlastAge == this.ds.calcLastAge(this.dobtemp.slice(0, 10))
    ) {
      false;
    } else {
      return true;
    }
  }

  getdob(e, birthind) {
    if (birthind === "agenearest") {
      if (this.blureffect === true && this.checkAgeChange()) {
        this.dobtemp = this.ds.calcBirthYear(this.birthnearestAge);
        this.dob = this.datepipe.transform(this.dobtemp, "MM/dd/yyyy");
        this.birthlastAge = this.ds.calcLastAge(this.dobtemp.slice(0, 10));
      }
    }
    if (birthind === "agelast") {
      if (this.blureffect === true && this.checkAgeChange()) {
        this.dobtemp = this.ds.calcBirthYear(this.birthlastAge);
        this.dob = this.datepipe.transform(this.dobtemp, "MM/dd/yyyy");
        this.birthnearestAge = this.ds.calcAge(this.dobtemp.slice(0, 10));
      }
    }
    this.blureffect = true;
    if (this.dob) {
      this.dobFlag = true;
      this.ageValue();
    } else {
      this.dobFlag = false;
    }
    this.ds.setInpputDob(this.dobFlag);
  }
  getThatDob() {
    this.doberr = "";
    if (this.ds.validatedate(this.dob) === true) {
      this.dobtemp = this.dob;
      this.dobtemp = this.datepipe.transform(this.dob, "yyyy-MM-dd");
      this.loaddob(this.dobtemp);
      this.birthnearestAge = this.ds.calcAge(this.dobtemp.slice(0, 10));
      this.birthlastAge = this.ds.calcLastAge(this.dobtemp.slice(0, 10));
      this.ageValue();
    } else {
      this.dobinput.setFocus();
      this.dobFlag = false;
      this.doberr = "Please enter a past date of birth in mm/dd/yyyy format";
    }
    this.blureffect = false;
    this.ds.setInpputDob(this.dobFlag);
  }
  dollar() {
    if (isNaN(this.amount)) {
      this.amount = 0;
    }
    this.amountTemp = this.amount;
    if (this.solvee === "face") {
      this.amount = this.currencyPipe.transform(
        this.amount,
        "USD",
        "symbol",
        "1.2-2"
      );
    } else {
      this.amount = this.currencyPipe.transform(
        this.amount,
        "USD",
        "symbol",
        "1.0-0"
      );
    }
    this.dolrLength = 14;
    if (this.amount === null || Number(this.amountTemp) === 0) {
      this.amountFlag = false;
    } else if (this.amount.length > 0 || this.amount != null) {
      this.amountFlag = true;
    }
    this.ds.setInpputDolr(this.amountFlag);
  }
  dollarSelect() {
    this.dolrLength = 8;
    this.amount = this.amountTemp;
  }
  ageValue() {
    if (this.birthnearestAge <= this.juv.getJA()) {
      this.rateClassList = this.ds.GetRateClassDetails(
        this.prodTyp,
        this.birthnearestAge
      );
      this.rateClass = this.ds.getJuvenileRateClass();
    }
    if (this.birthnearestAge > this.juv.getJA()) {
      this.rateClassList = this.ds.GetRateClassDetails(
        this.prodTyp,
        this.birthnearestAge
      );
      if (!this.rateClass) this.rateClass = 0;
      if (this.rateClass === this.ds.getJuvenileRateClass()) this.rateClass = 0;
    }
  }
  formatdate() {
    if (this.dob) {
      this.dob = this.dobtemp.slice(0, 10);
      //console.log("dob:"+this.dob);
      this.dob = this.datepipe.transform(this.dob, "MM/dd/yyyy");
    }
  }
  prodStateSelect(){   
    let selectedindex = this.stateValueList.indexOf(Number(this.stateValue));
    this.state = this.stateList[selectedindex].text;
  }
  prodTypeSelect() {
    //console.log("194 prodtyp:"+this.prodTyp + ' rateClass:'+ this.rateClass);
    this.rateClassList = this.ds.GetRateClassDetails(
      this.prodTyp,
      this.birthnearestAge
    );
    if (this.prodTyp === "0") {
      this.rateClass = 0;
    }
    if (this.birthnearestAge <= this.juv.getJA()) {
      this.rateClass = 6;
    }
  }
  solveSelect() {
    if (this.solvee === "face") {
      this.solvelabel = this.dataLan.solve.t3;
    }
    if (this.solvee === "prem") {
      this.solvelabel = this.dataLan.solve.t4;
    }
  }
  kroCheck() {
    this.dataServiceDataOrig = {
      cName: this.ds.getData().cName,
      dob: this.ds.getData().dob,
      gender: this.ds.getData().gender,
      genVal: this.ds.getData().genVal,
      city: this.ds.getData().city,
      stateValue: this.ds.getData().stateValue,
      solve: this.ds.getData().solve,
      amount: this.ds.getData().amount,
      freq: this.ds.getData().freq,
      solvelabel: this.ds.getData().solvelabel,
      rateClass: this.ds.getData().rateClass,
      prodTyp: this.ds.getData().prodTyp,
      rcValue: this.ds.getData().rcValue,
      rateClassList: this.ds.getData().rateClassList,
    };
    this.ds.setTheme();
    this.stateList = this.usState.getStates();
    for (let i = 0; i < this.stateList.length; i++) {
      this.stateValueList.push(this.stateList[i].value);
    }
    this.minYearDisplay = this.ds.getMinDate();
    //this.minYearDisplay=this.dX684.getMinDate();
    this.today = this.ds.getToday();
    this.lO = this.ds.getLan();
    if (this.lO === "eng") {
      this.dataLan = this.en.getCinf();
      this.dL = this.en.getQD();
    } else if (this.lO === "frn") {
      this.dataLan = this.fr.getCinf();
      this.dL = this.fr.getQD();
    }
    //document.getElementById("logo").innerHTML = "Hello World";
  }
  doChange(ind) {
    if (ind === "Cancel") {
      this.ds.setData(this.dataServiceDataOrig);
      this.ds.setNavPath("Cancel");
      this.analyticsService.logEvent(AnalyticsEventNames.clientInfoCancel, {
        page_name: "Client Info",
      });
      this.modalController.dismiss();
    }
    if (ind === "Save") {
      var genVal;
      if (this.gen === "Male") {
        genVal = 0;
      } else if (this.gen === "Female") {
        genVal = 1;
      }
      if (this.ds.validatedate(this.dob)) {
        this.dataServiceData.cName = this.cName;
        this.dataServiceData.dob = this.datepipe.transform(
          this.dob,
          "yyyy-MM-dd"
        );
        this.dataServiceData.gender = this.gen;
        this.dataServiceData.genVal = genVal;
        this.dataServiceData.city = this.state;
        this.dataServiceData.stateValue = Number(this.stateValue);
        this.dataServiceData.solve = this.solvee;
        this.dataServiceData.amount = this.amountTemp;
        this.dataServiceData.freq = this.freq;
        this.dataServiceData.solvelabel = this.solvelabel;
        this.dataServiceData.rateClass = this.rateClass;
        this.dataServiceData.prodTyp = this.prodTyp;
        this.dataServiceData.rcValue = Number(this.rateClass);
        this.dataServiceData.genVal = genVal;
        this.dataServiceData.rateClassList = this.rateClassList;
        this.ds.setData(this.dataServiceData);
        this.ds.setNavPath("Call Function");
        if (this.getvalidation()) {
          this.analyticsService.logEvent(AnalyticsEventNames.clientInfoSave, {
            page_name: "Client Info",
          });
          //this.ds.setModeEntery('sp','/home/quote-compare-only-product');
          this.modalController.dismiss();
        }
      }
    }
    if (ind === "QuoteDetails") {
      var genVal;
      if (this.gen === "Male") {
        genVal = 0;
      } else if (this.gen === "Female") {
        genVal = 1;
      }
      if (this.ds.validatedate(this.dob)) {
        this.dataServiceData.cName = this.cName;
        this.dataServiceData.dob = this.datepipe.transform(
          this.dob,
          "yyyy-MM-dd"
        );
        this.dataServiceData.gender = this.gen;
        this.dataServiceData.genVal = genVal;
        this.dataServiceData.city = this.state;
        this.dataServiceData.stateValue = Number(this.stateValue);
        this.dataServiceData.solve = this.solvee;
        this.dataServiceData.amount = this.amountTemp;
        this.dataServiceData.freq = this.freq;
        this.dataServiceData.solvelabel = this.solvelabel;
        this.dataServiceData.rateClass = this.rateClass;
        this.dataServiceData.prodTyp = this.prodTyp;
        this.dataServiceData.rcValue = Number(this.rateClass);
        this.dataServiceData.genVal = genVal;
        this.dataServiceData.rateClassList = this.rateClassList;
        this.ds.setData(this.dataServiceData);
        this.ds.setNavPath("Call Function");
        if (this.getvalidation()) {
          this.analyticsService.logEvent(AnalyticsEventNames.clientInfoSave, {
            page_name: "Client Info",
          });
          this.getProdApi();
        }
      }
    }
  }

  getvalidation() {
    var returnval = true;
    this.cNameerr = "";
    this.doberr = "";
    this.amounterr = "";
    this.birthnearestAgeerr = "";
    this.birthlastAgeerr = "";
    if (this.cName === "") {
      this.cNameerr = "Please enter Client Name";
      returnval = false;
    }
    if (this.dob === "") {
      this.doberr = "Please enter Date of Birth";
      returnval = false;
    }
    if (this.birthnearestAge === null) {
      this.birthnearestAgeerr = "Please enter age nearest";
      returnval = false;
    } else if (this.birthnearestAge < 0) {
      this.birthnearestAgeerr = "Please enter age nearest";
      returnval = false;
    }
    if (this.birthlastAge === null) {
      this.birthlastAgeerr = "Please enter last age";
      returnval = false;
    } else if (this.birthlastAge < 0) {
      this.birthlastAgeerr = "Please enter last age";
      returnval = false;
    }
    //console.log(this.ds.getAgeRange(this.ds));
    if (this.ds.getAgeRange(this.ds) != "Valid Range") {
      this.birthnearestAgeerr = this.ds.getAgeRange(this.ds);
      returnval = false;
    }
    if (this.state === null) {
      this.stateerr = "Please enter State";
      returnval = false;
    }
    if (this.ds.validateRange(this.ds) === false) {
      this.amounterr = this.ds.getFacePremRange(
        this.prodTyp,
        this.solvee,
        this.freq
      ).msgeng;
      returnval = false;
    }
    if (this.productname === this.dataLan.quotecomparepage.p6) {
      //console.log(this.solvee+" "+this.amountTemp);
      if (
        this.solvee === "prem" &&
        (this.amountTemp < 5000 || this.amountTemp > 75000)
      ) {
        this.amounterr = "Please enter an amount between $5,000 and $75,000";
        returnval = false;
      }
      if (this.birthlastAge > 17) {
        this.birthlastAgeerr = "Please enter an age <=17";
        returnval = false;
      }
      //console.log(this.state);
      if (this.state === "NY" || this.state === "WA") {
        this.producterr =
          "The product is not sold in the state of NY and WA";
        returnval = false;
      }
    }
    if (this.productname === this.dataLan.quotecomparepage.p7) {
      console.log(this.dataLan.quotecomparepage.p7+" "+this.state);
      if (this.state === "NM") {
        this.producterr =
          "The product is not sold in the state of NM";
        returnval = false;
      }
    }
    return returnval;
  }
  async getProdApi() {
    const loading = await this.loadingController.create({
      message: "Loading",
      //duration: 1000,
    });

    if (this.productname != this.dataLan.quotecomparepage.p6) {
      await loading.present();
      this.storage.get("channel").then((val) => {
        this.channelcode = val;
        this.storage.get("QuerySubChannel").then((val) => {
          this.subchannelcode = val;
          this.px62 = "";
          this.px62 +=
            "$$OpusPair$$" + "channel=" + "$$OpusVar$$" + this.channelcode;
          this.px62 +=
            "$$OpusPair$$" +
            "subchannel=" +
            "$$OpusVar$$" +
            this.subchannelcode;
          this.px62 +=
            "$$OpusPair$$" +
            "productType=" +
            "$$OpusVar$$" +
            this.dataServiceData.prodTyp;
          this.px62 +=
            "$$OpusPair$$" +
            "Age=" +
            "$$OpusVar$$" +
            this.ds.calcAge(this.dataServiceData.dob);
          this.px62 +=
            "$$OpusPair$$" +
            "SecondAge=" +
            "$$OpusVar$$" +
            this.ds.calcLastAge(this.dataServiceData.dob);
          this.px62 +=
            "$$OpusPair$$" +
            "RateClass=" +
            "$$OpusVar$$" +
            this.dataServiceData.rcValue;
          this.px62 +=
            "$$OpusPair$$" +
            "Gender=" +
            "$$OpusVar$$" +
            this.dataServiceData.genVal;
          if (this.dataServiceData.solve === "prem") {
            this.px62 +=
              "$$OpusPair$$" +
              "SolveAmount=" +
              "$$OpusVar$$" +
              this.dataServiceData.amount;
          } else if (this.dataServiceData.solve === "face") {
            this.px62 +=
              "$$OpusPair$$" +
              "PremiumAmount=" +
              "$$OpusVar$$" +
              this.dataServiceData.amount;
          }
          this.px62 +=
            "$$OpusPair$$" +
            "State=" +
            "$$OpusVar$$" +
            this.dataServiceData.stateValue;
          this.px62 +=
            "$$OpusPair$$" + "productName=" + "$$OpusVar$$" + this.productname;
          this.px62 +=
            "$$OpusPair$$" +
            "Frequency=" +
            "$$OpusVar$$" +
            this.dataServiceData.freq;
          //this.px62 += '$$Opusriders$$=[]';
          //console.log(this.ds.getUL1Encrypted() +this.px62);
          this.http
            .get(this.ds.getUL1Encrypted() + this.ds.Encrypt(this.px62))
            .subscribe((data) => {
              this.prod = this.ds.DecryptReturnVal(data);
              //console.log("this.prod:"+this.prod);
              loading.dismiss();
              loading.onDidDismiss().then((e) => {
                if (this.prod.types != null) {
                  //console.log(this.prod.types[0]);
                  //console.log(this.prod.types[0].subtype[0].plan);
                  if (this.CheckDisplay(this.prod.types[0].subtype[0].plan)) {
                    this.ds.setQdOpD(this.prod.types[0]);
                    this.ds.setQdD(this.prod.types[0]);
                    this.ds.setModeEntery(
                      "spcolorchange",
                      "/quote-details-only-product"
                    );
                    this.modalController.dismiss();
                  }
                } else {
                  this.producterr =
                    "Product is not available for the product type";
                }
              });
            });
        });
      });
    } else {
      //console.log("inside else")
      this.ds.setModeEntery("jv", "/juvenile-input-screen");
      this.modalController.dismiss();
    }
  }
  CheckDisplay(nm) {
    var count = 0;
    var msg = [];
    for (let i of nm) {
      //msg.push(i.amount);
      let am = this.ds.ctXFor(i.amount);
      am = Number(am);
      if (isNaN(am) || am === 0) {
        if (i.amount != "") {
          msg.push(i.amount);
          this.producterr = i.amount;
          //console.log("this.producterr:"+this.producterr);
        }
        count++;
      }
    }
    this.producterr = msg[0];
    //console.log("array length:"+nm.length);
    //console.log("count:"+count);
    //console.log("this.producterr:"+this.producterr);
    if (count != nm.length) {
      this.producterr = "";
      return true;
    } else {
      return false;
    }
  }
  SelectedRateClass(ev: Event) {
    //console.log("on client before getselected:",this.dataServiceData.rateClass);
    this.ds.setSelectedRateClass(this.ds.GetRateClassName(this.rateClass));
    //this.dataServiceData.rateClass = this.rateClass;
    //console.log("on client change:",this.rateClass)
    //console.log("on client after getselected:",this.dataServiceData.rateClass);
  }
}
