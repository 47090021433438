import { Component, OnInit,ViewChild } from '@angular/core';
import { Platform,NavParams, ModalController,NavController,LoadingController} from '@ionic/angular';
import { CurrencyPipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../services/data.service';
import { HttpClient } from '@angular/common/http';
import { EngLaService } from '../../propertiesFile/eng-la.service';
import { FrnLaService } from '../../propertiesFile/frn-la.service';

@Component({
  selector: 'app-payment-mode',
  templateUrl: './payment-mode.page.html',
  styleUrls: ['./payment-mode.page.scss'],
})
export class PaymentModePage implements OnInit {
  langOpt;
  dLan;
  dL1;
  pL;
  StringArray=[];
  slNo=[];
  Monthly =[];
  Annual=[];
  RiderDesc=[];
  source;
  constructor(public loadingController: LoadingController,public platform:Platform,private route: ActivatedRoute,/*private navParams: NavParams, */private modalController: ModalController, public currencyPipe: CurrencyPipe, private navCtrl :NavController, public dX684: DataService, private http: HttpClient, private en: EngLaService,private fr: FrnLaService) { 
    this.route.queryParams.subscribe(params => {
      this.kroCheck();
      //this.pL=this.navParams.get('prod');
      this.pL=this.dX684.getQconlyProduct().pl;
      this.StringArray=[];
      this.slNo=[];
      this.Monthly=[];
      this.Annual=[];
      this.RiderDesc=[];
      this.StringArray=this.dX684.getQconlyProduct().stringArray.stringArray;
      this.source=this.dX684.getQconlyProduct().source;
      if(this.StringArray[13].SArray[0]!='0'){
        this.Monthly.push(this.StringArray[13].SArray[0]);
        this.RiderDesc.push("Waiver of Premium");
      }
      if(this.StringArray[21].SArray[0]!='0'){
        this.Monthly.push(this.StringArray[21].SArray[0]);
        this.RiderDesc.push("Disability Income Rider");
      }
      if(this.StringArray[17].SArray[0]!='0'){
        this.Monthly.push(this.StringArray[17].SArray[0]);
        this.RiderDesc.push("Return of Premium");
      }
      if(this.StringArray[14].SArray[0]!='0')
        this.Annual.push(this.StringArray[14].SArray[0]);
      if(this.StringArray[22].SArray[0]!='0')
        this.Annual.push(this.StringArray[22].SArray[0]);
      if(this.StringArray[18].SArray[0]!='0')
        this.Annual.push(this.StringArray[18].SArray[0]);
      for(let i=0; i<this.Monthly.length;i++){
        this.slNo.push(i);
      }
      //console.log(this.StringArray)
    })
  }
  kroCheck() {
    this.langOpt = this.dX684.getLan();
    if (this.langOpt === 'eng') {
      this.dLan = this.en.getQD();
      this.dL1 = this.en.getCinf();
    } else if (this.langOpt === 'frn') {
      this.dLan = this.fr.getQD();
      this.dL1 = this.fr.getCinf();
    }
  }
  ngOnInit() {
  }
  closeModal() {
    //this.modalController.dismiss();
    this.navCtrl.navigateBack(this.source);
  }
}
