import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // { path: '', redirectTo: 'home', pathMatch: 'full' },
  //{ path: '', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  { path: 'how-much-insurance', loadChildren: './how-much-insurance/how-much-insurance.module#HowMuchInsurancePageModule', pathMatch: 'full'  },
  { path: 'know-your-product', loadChildren: './know-your-product/know-your-product.module#KnowYourProductPageModule', pathMatch: 'full'  },
  { path: 'know-your-product-detail/:id', loadChildren: './know-your-product-detail/know-your-product-detail.module#KnowYourProductDetailPageModule', pathMatch: 'full' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' , pathMatch: 'full' },
  { path: 'resour', loadChildren: './resour/resour.module#ResourPageModule', pathMatch: 'full'  },
  { path: 'pref', loadChildren: './pref/pref.module#PrefPageModule' , pathMatch: 'full' },
  { path: 'temp-direct', loadChildren: './temp-direct/temp-direct.module#TempDirectPageModule' },
  { path: 'temp-direct/:id', loadChildren: './temp-direct/temp-direct.module#TempDirectPageModule' },
  { path: 'e-app', loadChildren: './Personal_info/e-app/e-app.module#EAppPageModule' },
  { path: 'personal-info', loadChildren: './eApp/personal-info/personal-info.module#PersonalInfoPageModule' },
  { path: 'juvenile-client-input', loadChildren: './juvenile-client-input/juvenile-client-input.module#JuvenileClientInputPageModule' },
  { path: 'juvenile-input-screen', loadChildren: './juvenile-input-screen/juvenile-input-screen.module#JuvenileInputScreenPageModule' },
  { path: 'juvenile-quote-details', loadChildren: './juvenile-quote-details/juvenile-quote-details.module#JuvenileQuoteDetailsPageModule' },
  { path: 'modal-qconly-product', loadChildren: './addional/modal-qconly-product/modal-qconly-product.module#ModalQCOnlyProductPageModule' },
  { path: 'alter-popover', loadChildren: './addional/alter-popover/alter-popover.module#AlterPopoverPageModule' },
  { path: 'quote-compare-only-product', loadChildren: './quote-compare-only-product/quote-compare-only-product.module#QuoteCompareOnlyProductPageModule' },
  { path: 'quote-compare', loadChildren: './quote-compare/quote-compare.module#QuoteComparePageModule' },
  { path: 'quote-details', loadChildren: './quote-details/quote-details.module#QuoteDetailsPageModule' },
  { path: 'quote-details-only-product', loadChildren: './quote-details-only-product/quote-details-only-product.module#QuoteDetailsOnlyProductPageModule' },
  { path: 'client-basic-input', loadChildren: './client-basic-input/client-basic-input.module#ClientBasicInputPageModule' },
  { path: 'landing', loadChildren: './landing/landing.module#LandingPageModule' },
  { path: 'finger-print-auth', loadChildren: './finger-print-auth/finger-print-auth.module#FingerPrintAuthPageModule' },
  { path: 'client-edit', loadChildren: './addional/client-edit/client-edit.module#ClientEditPageModule' },
  { path: 'juvenile-client-popover', loadChildren: './addional/juvenile-client-popover/juvenile-client-popover.module#JuvenileClientPopoverPageModule' },
  { path: 'gcvrup-cidi-popover', loadChildren: './addional/gcvrup-cidi-popover/gcvrup-cidi-popover.module#GcvrupCidiPopoverPageModule' },
  { path: 'payment-mode', loadChildren: './addional/payment-mode/payment-mode.module#PaymentModePageModule' },
  {
    path: 'needs-analysis',
    loadChildren: () => import('./needs-analysis/needs-analysis.module').then( m => m.NeedsAnalysisPageModule)
  },
//  {
//    path: 'administration',
//    loadChildren: () => import('./administration/administration.module').then( m => m.AdministrationPageModule)
//  },
  {
    path: 'version-update',
    loadChildren: () => import('./addional/version-update/version-update.module').then( m => m.VersionUpdatePageModule)
  },
  ];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
