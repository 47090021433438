import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { DataService } from '../../services/data.service';
@Component({
  selector: 'app-payee-type-popover',
  templateUrl: './payee-type-popover.page.html',
  styleUrls: ['./payee-type-popover.page.scss'],
})
export class PayeeTypePopoverPage implements OnInit {

  constructor(
    private navParams: NavParams,
    private popoverController: PopoverController,
    private dataService: DataService) {
      this.payeeTypeList = this.dataService.getDataToPayeeTypePopover();
  }
  payeeTypeList;
  setSelectedPayeeType;
  ngOnInit() {
  }
  doChange(ev) {
    this.dataService.setSelectedPayeeType(this.setSelectedPayeeType);
    //console.log(this.setSelectedPayeeType);
    this.popoverController.dismiss();
   }
}
