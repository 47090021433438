import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { Storage} from '@ionic/storage';
export class AnalyticsEventNames {
  static guestLogin: string = "Guest_Login_Web";
  static ProducerIdLogin: string = "ProducerId_Login_Web";
  static pageViewClientInfo: string = "PV_Client_Info_Web";
  static clientInfoSave: string = "Client_Info_Save_Web";
  static clientInfoCancel: string = "Client_Info_Cancel_Web";
  static pageViewProductSelectionList: string = "PV_Product_Selection_List_Web";
  static pageViewProductSelectionSlide: string = "PV_Product_Selection_Slide_Show_Web";
  static pageViewProductDetails: string = "PV_Product_Details_Web";
  static getQoute: string = "Get_Quote_Web";
  static forestersBiz: string = "Foresters_Biz_Web";
  static pageViewPreferences: string = "PV_Preferences_Web";
  static savePreferences: string = "Save_Preferences_Web";
  static resources: string = "Resources_Web";
  static sendEmail: string = "Email_Sent_Regular_Web";
  static gcvRUP: string = "GCV_RUP_Web";
  static costIndices: string = "Cost_Indices_Web";
  static calculateQuote: string = "Calculate_Quote_Regular_Web";
  static calculateQuoteJuvenile: string = "Calculate_Quote_Juvenile_Web";
  static checkMEC: string = "MEC_Web";
  static sendEmailJuvenile: string = "Email_Sent_Juvenile_Web";
  static gcvRUPJuvenile: string = "GCV_RUP_Juvenile_Web";
  static costIndicesJuvenile: string = "Cost_Indices_Juvenile_Web";
  static insurenceNeedsNextPev: string = "Insurance_Needs_Web";
  static insurenceNeedsCanculate: string = "Insurence_Needs_Canculate_Web"; 
  static productclickevent = "Product Selection Web";
  static yourAssets = "Insurence_Needs_Your_Assets_Web";
  static whatsnew = "Latest_News_Web";
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(private analytics: AngularFireAnalytics, private storage: Storage) {}

  logEvent(eventName: string, parameters: any = {}) {
    parameters.timestamp = new Date().toString();
    this.storage.get('producerID').then ( (producerID)=> {
      parameters.producerID = producerID;
      //console.log(parameters);
      this.analytics.logEvent(eventName, parameters)
      .then(() => {
        //console.log("Analytics success: " + eventName +" --> ", parameters)
      }) 
      .catch(() => {
        //console.error("Analytics fail: " + eventName +" --> ", parameters)
      });
    });
  }
}