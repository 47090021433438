import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { CurrencyPipe } from '@angular/common';
@Component({
  selector: 'app-error-popover',
  templateUrl: './error-popover.page.html',
  styleUrls: ['./error-popover.page.scss'],
})
export class ErrorPopoverPage implements OnInit {
  x;
  min;
  max;
  constructor(private navParams: NavParams,private ctp: CurrencyPipe, private popoverController: PopoverController) {
      this.x = this.navParams.get('custom_id');
      this.min = this.ctp.transform(this.x.min, 'USD', 'symbol', '1.0-0');
      this.max = this.ctp.transform(this.x.max, 'USD', 'symbol', '1.0-0');
  }
 
  ngOnInit() {
  }
  doChange(ev) {
    
    this.popoverController.dismiss();
   }
}
