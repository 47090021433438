import { Injectable, RendererFactory2, Inject, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Storage} from '@ionic/storage';
import { NavController, ToastController, AlertController, MenuController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';
//import { networkInterfaces } from 'os';
//import { ConnectionService } from 'ng-connection-service';
const dXStrKY = 'nmkbjbjKX238dx';
import { Network } from '@ionic-native/network/ngx'; 
import { Platform } from '@ionic/angular';
import * as CryptoJS from 'crypto-js';
import { JuvenileAgeService } from '../propertiesFile/juvenile-age.service';
//import { HttpParameterCodec } from "@angular/common/http"
import { DOCUMENT } from '@angular/common';
//import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import * as EmailValidator from 'email-validator';
import { BrowserTab } from '@ionic-native/browser-tab/ngx';
//import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { FingerprintAIO ,FingerprintOptions} from '@ionic-native/fingerprint-aio/ngx';
import { StateWithCodesService } from '../propertiesFile/state-with-codes.service';
import { AppLauncher, AppLauncherOptions } from '@ionic-native/app-launcher/ngx';
import { AppAvailability } from '@ionic-native/app-availability/ngx';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private lan;
  private newName;
  private agentName;
  private statelist:StateWithCodesService;
  private navpath;
  private howMuchInsurance;
  private dX94;
  private loginInfo;
  private inpputDob = false;
  private inpputDolr = false;
  private inpputSt = false;
  private quoteDetailSelectedRateClass: string;
  private quoteDetailSelectedProductType: string;
  private quoteDetailSelectedPayeeType: string;
  private agentkey:'activation code';
  private agentvalue:string;
  private activationcodeval;
  private expiryDate = new Date();
  private home;
  private validatetokenurl;
  private numconvertcurrency;
  private tokenvalidity;
  private activationcode;
  private State;
  private gcvrpucidi;
  private secretkey:string="1099654682022010";
  private eAppDetails;
  private qcOnlyJson;
  private routertabstyle;
  private fingerPrintInd;
  private gcvrupcididetails;
  public versionnumber = '1.5.6';
  private versionUpdateDetails;
  //private apiurl = "https://quotesdev.foresters.com";
  private apiurl = "https://quotes.foresters.com";
  //private apiurl = "https://foresters.iidemo.com/mobileservice";
  private subrating;
  private rateClassesForCurrentProduct:any = ['Non-Tobacco','Tobacco','Tobacco Plus','Non-Tobaco Plus','Preferred Non-Tobacco','Preferred Plus Non-Tobaco','Juvenile'];
  private rateClassesForCurrentProductFr:any = ['Sans-Tabac','le tabac',   'Tabac Plus', 'Sans-Tabac Plus','Non-Tabac Préféré','Préféré Plus Sans Tabac','Juvénile'];
  private productTypeForCurrentProduct = ['Medical', 'Non-Medical'];
  private productTypeForCurrentProductFr = ['Médical', 'Non-Médical'];
  //private payeeTypeForCurrentPayee = ['Annually', 'Semiannually', 'Quarterly', 'Monthly'];
  private payeeTypeForCurrentPayee = ['Annual','Monthly'];
  //private payeeTypeForCurrentPayeeFr = ['Annuellement', 'Semestriellement', 'Trimestriel', 'Mensuel'];
  private payeeTypeForCurrentPayeeFr = ['Annuelle', 'Mensuelle'];
 // private mpDXRtV = [1,5,0,4,3,2];
  private mpDXRtV = [0,1,5,4,3,2,6];
  public pNL = ['Advantage Plus II', 'Strong Foundation', 'PlanRight', 'Your Term', 'SMART UL', 'BrightFuture', 'Prepared Accidental Death'];
  private adr;
  private qdXt;
  private wpr;
  private gir;
  private ctr;
  private t10;
  private t20;
  private dir;
  private qdOpData;
  private wp;
  private wmd;
  private gpo;
  private rop;
  tempData;
  private qdDNm;
  private e;
  private qdDPl;
  private qdDAm;
  private riderstatus;
  private juvedetailsjson;
  modeEntery: any;
  renderer:Renderer2;
  private theme;
  private qdDAm1;
  constructor(private route: ActivatedRoute, 
    public alertController:AlertController,
    private btb: BrowserTab , 
    public menuCtrl:MenuController,
    public network: Network,
    public platform: Platform,
    private currencyPipe: CurrencyPipe, 
    private datepipe:DatePipe, 
    private fingerAuth: FingerprintAIO, 
    private appLauncher: AppLauncher,
    private appAvailability: AppAvailability,  
    private toastController: ToastController, 
    private juve:JuvenileAgeService,
    private navCtrl: NavController, 
    private _dKwStr23: Storage,
    //private androidPermissions: AndroidPermissions,
    private rendererFactory:RendererFactory2, @Inject(DOCUMENT) private document: Document,
    private http: HttpClient, ) {
      this.renderer=this.rendererFactory.createRenderer(null,null);
	  //this.setTheme('blue-theme')
    this.route.queryParams.subscribe(params => {this.dX94;});
  }
  setTheme(){
    this.renderer.removeClass(this.document.body,'blue-theme');
	  this.renderer.removeClass(this.document.body,'purple-theme');
	  this.renderer.removeClass(this.document.body,'black-theme');
    this.renderer.removeClass(this.document.body,'green-theme');
    this._dKwStr23.get('theme').then ( (val)=>{
      this.theme=val;})
    if(!this.theme){
      this.renderer.addClass(this.document.body,'purple-theme');
    }
    else{
      this.renderer.addClass(this.document.body,this.theme);
    }
  }
  
  getPlX(){
    return this.pNL;
  }
  fdXRcXV(rn){
    let rtR;
    const cX = this.getDataToRateClassPopover();
    for(let i=0;i<cX.length;i++){
      if(cX[i] === rn){
        rtR = this.mpDXRtV[i];
        return rtR;
      }
    }
    return rtR;
  }
  getRide(fP) {
    this.adr = null;
    this.wpr = null;
    this.gir = null;
    this.ctr = null;
    this.t10 = null;
    this.t20 = null;
    this.dir = null;
    this.wp = null;
    this.wmd = null;
    this.gpo = null;
    this.rop=null;
    this.subrating=null;
    if(fP.trim() === this.pNL[0]) {
      this.adv2RDMap();
    }  else if(fP.trim() === this.pNL[1]) {
      this.strFndMap();
    } else if(fP.trim() === this.pNL[2]) {
      this.plnRtMap();
    } else if(fP.trim() === this.pNL[3]) {
      this.yrTrmMap();
    } else if(fP.trim() === this.pNL[4]) {
      this.smtUlMap();
    } else if(fP.trim() === this.pNL[6]) {
      this.preparedmap();
    }
    const rtr = {
      adrRt: this.adr,
      wprRt: this.wpr,
      girRt: this.gir,
      ctrRt: this.ctr,
      t10Rt: this.t10,
      t20Rt: this.t20,
      dirRt: this.dir,
      wpRt: this.wp,
      wmdRt: this.wmd,
      gpoRt: this.gpo,
      ropRt:this.rop,
      subratingRt:this.subrating
    }
    return rtr;
  }
  getdXd(){
    return dXStrKY;
  }
  adv2RDMap() {
    // ADR
    this.adr = {
      valid: true,
      se: false,
      val: false,
      tb: {
        mnAg: 16,
        mxAg: 60,
        mnAm: false,
        mxAm: false
      },
      nTb: {
        mnAg: 16,
        mxAg: 60,
        mnAm: false,
        mxAm: false
      }
    }
    // ADR
    // /////////////////////////////////////////////////
    // CTR
    this.ctr = {
      valid: true,
      se: false,
      val: true,
      tb: {
        mnAg: 16,
        mxAg: 55,
        mnAm: 10000,
        mxAm: 25000,
        step:1000
      },
      nTb: {
        mnAg: 16,
        mxAg: 55,
        mnAm: 10000,
        mxAm: 25000,
        step:1000
      }
    }
    // CTR
    // /////////////////////////////////////////////////
    // GIR
    this.gir = {
      valid: true,
      se: false,
      val: false,
      tb: {
        mnAg: 0,
        mxAg: 37,
        mnAm: false,
        mxAm: false
      },
      nTb: {
        mnAg: 0,
        mxAg: 37,
        mnAm: false,
        mxAm: false
      }
    }
    // GIR
    // /////////////////////////////////////////////////
    // T10
    this.t10 = {
      valid: true,
      se: false,
      val: true,
      tb: {
        mnAg: 18,
        mxAg: 75,
        mnAm: 10000,
        mxAm: 10000000
      },
      nTb: {
        mnAg: 18, 
        mxAg: 75,
        mnAm: 10000,
        mxAm: 10000000
      }
    }
    // T10
    // /////////////////////////////////////////////////
    // T20
    this.t20 = {
      valid: true,
      se: false,
      val: true,
      tb: {
        mnAg: 18,
        mxAg: 65,
        mnAm: 10000,
        mxAm: 10000000
      },
      nTb: {
        mnAg: 18,
        mxAg: 65,
        mnAm: 10000,
        mxAm: 10000000
      }
    }
    // T20
    // /////////////////////////////////////////////////
    // WPR
    this.wpr = {
      valid: true,
      se: false,
      val: false,
      tb: {
        mnAg: 16,
        mxAg: 55,
        mnAm: false,
        mxAm: false
      },
      nTb: {
        mnAg: 16,
        mxAg: 55,
        mnAm: false,
        mxAm: false
      }
    }
    // WPR
    // /////////////////////////////////////////////////
  }
  strFndMap() {
    // ADR
    this.adr = {
      valid: true,
      se: false,
      val: false,
      tb: {
        mnAg: 18,
        mxAg: 60,
        mnAm: false,
        mxAm: false
      },
      nTb: {
        mnAg: 18,
        mxAg: 60,
        mnAm: false,
        mxAm: false
      }
    }
    // ADR
    // /////////////////////////////////////////////////
    // CTR
    this.ctr = {
      valid: true,
      se: false,
      val: true,
      tb: {
        mnAg: 18,
        mxAg: 55,
        mnAm: 10000,
        mxAm: 25000,
        step:1000
      },
      nTb: {
        mnAg: 18,
        mxAg: 55,
        mnAm: 10000,
        mxAm: 25000,
        step:1000
      }
    }
    // CTR
    // /////////////////////////////////////////////////
    // DIR
    /*this.dir = {
      valid: false,
      se: [5, 6, 15, 17, 20, 21, 31, 32, 39, 45],
      val: false,
      tb: {
        mnAg: 18,
        mxAg: 60,
        mnAm: false,
        mxAm: false
      },
      nTb: {
        mnAg: 18,
        mxAg: 60,
        mnAm: false,
        mxAm: false
      }
    }*/
    // DIR
    // /////////////////////////////////////////////////
    // WP
    this.wp = {
      valid: true,
      se: false,
      val: false,
      agestexptb:{
        mnAg: 56,
        mxAg: 64,
        apst:[5],
        mnAm: false,
        mxAm: false 
      },
      agestexpntb:{
        mnAg: 56,
        mxAg: 64,
        apst:[5],
        mnAm: false,
        mxAm: false 
      },
      tb: {
        mnAg: 18,
        mxAg: 55,
        mnAm: false,
        mxAm: false
      },
      nTb: {
        mnAg: 18,
        mxAg: 55,
        mnAm: false,
        mxAm: false
      }
    }
    // WP
    // /////////////////////////////////////////////////
    // Sub Rating
    this.subrating = {
      valid: true,
      se: false,
      val: true,
      options:{
        1:{
          tb: {
            mnAg: 18,
            mxAg: 55,
            mnAm: 175,
            mxAm: 300,
            minFace:50000,
            maxFace:300000,
            step:25,
            postadd:["G","H","I","J","K","L"]
          },
          nTb: {
            mnAg: 18,
            mxAg: 55,
            mnAm: 175,
            mxAm: 300,
            minFace:50000,
            maxFace:300000,
            step:25,
            postadd:["G","H","I","J","K","L"]
          },
        },
        2:{
          tb: {
            mnAg: 56,
            mxAg: 80,
            mnAm: 175,
            mxAm: 300,
            minFace:50000,
            maxFace:150000,
            step:25,
            postadd:["G","H","I","J","K","L"]
          },
          nTb: {
            mnAg: 56,
            mxAg: 80,
            mnAm: 175,
            mxAm: 300,
            minFace:50000,
            maxFace:150000,
            step:25,
            postadd:["G","H","I","J","K","L"]
          }
        }
      }
    }
    // Subrating
    // /////////////////////////////////////////////////

  }
  plnRtMap() {
    // ADR
    this.adr = {
      valid: true,
      se: [5],
      val: false,
      tb: {
        mnAg: 50,
        mxAg: 80,
        mnAm: false,
        mxAm: false
      },
      nTb: {
        mnAg: 50,
        mxAg: 80,
        mnAm: false,
        mxAm: false
      }
    }
    // ADR
    // /////////////////////////////////////////////////
  }
  yrTrmMap() {
    // ADR
    this.adr = {
      valid: true,
      se: false,
      val: false,
      tb: {
        mnAg: 18,
        mxAg: 60,
        mnAm: false,
        mxAm: false
      },
      nTb: {
        mnAg: 18,
        mxAg: 60,
        mnAm: false,
        mxAm: false
      }
    }
    // ADR
    // /////////////////////////////////////////////////
    // CTR
    this.ctr = {
      valid: true,
      se: false,
      val: true,
      tb: {
        mnAg: 18,
        mxAg: 55,
        mnAm: 10000,
        mxAm: 25000,
        step:1000
      },
      nTb: {
        mnAg: 18,
        mxAg: 55,
        mnAm: 10000,
        mxAm: 25000,
        step:1000
      }
    }
    // CTR
    // /////////////////////////////////////////////////
    // DIR
    /*this.dir = {
      valid: false,
      se: [5, 6, 15, 17, 20, 21, 31, 32, 35, 39, 45],
      val: false,
      tb: {
        mnAg: 18,
        mxAg: 60,
        mnAm: false,
        mxAm: false
      },
      nTb: {
        mnAg: 18,
        mxAg: 60,
        mnAm: false,
        mxAm: false
      }
    }*/
    // DIR
    // /////////////////////////////////////////////////
    // WP
    this.wp = {
      valid: true,
      se: false,
      val: false,
      agestexptb:{
        mnAg: 56,
        mxAg: 64,
        apst:[5],
        mnAm: false,
        mxAm: false 
      },
      agestexpntb:{
        mnAg: 56,
        mxAg: 64,
        apst:[5],
        mnAm: false,
        mxAm: false 
      },
      tb: {
        mnAg: 18,
        mxAg: 55,
        mnAm: false,
        mxAm: false
      },
      nTb: {
        mnAg: 18,
        mxAg: 55,
        mnAm: false,
        mxAm: false
      }
    }
    // WP
    // /////////////////////////////////////////////////
  }
  smtUlMap() {
    // ADR
    this.adr = {
      valid: true,
      se: false,
      val: false,
      tb: {
        mnAg: 16,
        mxAg: 60,
        mnAm: false,
        mxAm: false
      },
      nTb: {
        mnAg: 16,
        mxAg: 60,
        mnAm: false,
        mxAm: false
      }
    }
    // ADR
    // /////////////////////////////////////////////////
    // CTR
    this.ctr = {
      valid: true,
      se: false,
      val: true,
      tb: {
        mnAg: 16,
        mxAg: 55,
        mnAm: 10000,
        mxAm: 25000,
        step:1000
      },
      nTb: {
        mnAg: 16,
        mxAg: 55,
        mnAm: 10000,
        mxAm: 25000,
        step:1000
      }
    }
    // CTR
    // /////////////////////////////////////////////////
    // GPO
    this.gpo = {
      valid: true,
      se: [35],
      val: false,
      tb: {
        mnAg: 0,
        mxAg: 37,
        mnAm: false,
        mxAm: false
      },
      nTb: {
        mnAg: 0,
        mxAg: 37,
        mnAm: false,
        mxAm: false
      }
    }
    // GPO
    // /////////////////////////////////////////////////
    // WMD
    this.wmd = {
      valid: true,
      se: false,
      val: false,
      tb: {
        mnAg: 16,
        mxAg: 55,
        mnAm: false,
        mxAm: false
      },
      nTb: {
        mnAg: 16,
        mxAg: 55,
        mnAm: false,
        mxAm: false
      }
    }
    // WMD
    // /////////////////////////////////////////////////
  }
  preparedmap(){
    // WPR
    this.wpr = {
      valid: true,
      se: [5, 6, 20, 24, 31, 32, 35, 42, 47],
      val: false,
      tb: {
        mnAg: 18,
        mxAg: 55,
        mnAm: false,
        mxAm: false
      },
      nTb: {
        mnAg: 18,
        mxAg: 55,
        mnAm: false,
        mxAm: false
      }
    }
    // WPR
    // /////////////////////////////////////////////////
    // DIR
    this.dir = {
      valid: true,
      se: [5, 6, 7, 10, 16, 20, 21, 24, 23, 31, 32, 35, 36, 39, 42, 44, 45, 47,],
      val: true,
      tb: {
        mnAg: 18,
        mxAg: 60,
        mnAm: 300,
        mxAm: 2000
      },
      nTb: {
        mnAg: 18,
        mxAg: 60,
        mnAm: 300,
        mxAm: 2000
      }
    }
    // DIR
    // /////////////////////////////////////////////////
    // ROP
    this.rop = {
      valid: true,
      se: [6, 7, 9, 11, 13, 15, 19, 20, 21, 24, 29, 31, 32, 35, 39, 42, 43, 44, 46, 47, 48,],
      statedefautselect:[],
      val: false,
      tb: {
        mnAg: 18,
        mxAg: 65,
        mnAm: false,
        mxAm: false
      },
      nTb: {
        mnAg: 18,
        mxAg: 65,
        mnAm: false,
        mxAm: false
      }
    }
    // ROP
    // /////////////////////////////////////////////////

  }
  getProducts() {
    //return this.productType;
  }
  getMyProducts() {
    //return this.myProducts;
  }
  getMyProductById(idgot) {
    /*const x = this.myProducts.chanel1;
    return x.filter(el => {
      return el.name === idgot;
    });*/
  }
  setData(data) {
    this.dX94 = data;
  }
  getData() {
    if (this.dX94) {
      return this.dX94;
    } else {
      return false;
    }

  }
  getDataToPayeeTypePopover() {
    if (this.payeeTypeForCurrentPayee || this.payeeTypeForCurrentPayeeFr) {
      if (this.getLan() === 'frn') {
        return this.payeeTypeForCurrentPayeeFr;
      } else {
        return this.payeeTypeForCurrentPayee;
      }
    }
  }
  getDataToRateClassPopover() {
    if (this.rateClassesForCurrentProduct || this.rateClassesForCurrentProductFr) {
      if (this.getLan() === 'frn') {
        return this.rateClassesForCurrentProductFr;
      } else {
        return this.rateClassesForCurrentProduct;
      }
    }
  }
  ctXFor(am){
	let re = /\,/gi;
    if(am){
	 am =  am.replace('monthly','');
     am =  am.replace('face amount','');
     am =  am.replace('$','');
     am =  am.replace(re, "");
     am =  am.replace(' ','');}
     return am;
  }
  ctXForDispay(am){
    var number =am;
    if(am){
      am =  am.replace('monthly','');
      am =  am.replace('face amount','');
      am =  am.replace('$','');
      am =  am.replace(',','');
      am =  am.replace(' ','');
    }
    if(isNaN(am)){
      return number;
    }
    return am;
  }
  getDataToProductTypePopover() {
    if (this.productTypeForCurrentProduct || this.productTypeForCurrentProductFr) {
      if (this.getLan() === 'frn') {
       // {id: this.mpDXRtV[1], name: rt[1]},
        return[{id:1,name:this.productTypeForCurrentProductFr[0]},
              {id:0,name:this.productTypeForCurrentProductFr[1]}] 
      } else {
        return[{id:1,name:this.productTypeForCurrentProduct[0]},
               {id:0,name:this.productTypeForCurrentProduct[1]}]
      }
    }
  }
  setTempData(abcd){
    this.tempData = abcd;
  }
  getTempData(){
    return this.tempData;
  }
  dTur_DX09Slv(xD){
    let _St;
    
    if(xD.solve === "prem"){
      _St = '&SolveAmount=';
    } else if(xD.solve === "face"){
      _St = '&PremiumAmount=';
    }
    return _St;
  }
  setSelectedRateClass(temp) {
    this.quoteDetailSelectedRateClass = temp;
  }
  getSelectedRateClass() {
    return this.quoteDetailSelectedRateClass;
  }
  setSelectedProductType(temp) {
    this.quoteDetailSelectedProductType = temp;
  }
  getSelectedProductType() {
    return this.quoteDetailSelectedProductType;
  }
  setSelectedPayeeType(temp) {
    this.quoteDetailSelectedPayeeType = temp;
  }
  getSelectedPayeeType() {
    return this.quoteDetailSelectedPayeeType;
  }
  saveLoginToken(token) {
    this.loginInfo = token;
  }
  checkFromLoinToken() {
    return this.loginInfo;
  }
  setInpputDob(abs) {
    this.inpputDob = abs;
  }
  setInpputDolr(abs) {
    this.inpputDolr = abs;
  }
  setInpputSt(abs) {
    this.inpputSt = abs;
  }
  getInpputDob() {
    return this.inpputDob;
  }
  getInpputDolr() {
    return this.inpputDolr;
  }
  getInpputSt() {
    return this.inpputSt;
  }
  calcLastAge(dob){
    if (dob) {
      const y = parseInt((dob).slice(0, 4));
      const m = parseInt((dob).slice(5, 7));
      const d = parseInt((dob).slice(8, 10));
      const now = new Date();
      const nowMonth = now.getMonth()+1;
      const nowDay = now.getDate();
      const nowYear = now.getFullYear();
      let agediff= nowYear-y;
      let monthdiff = nowMonth - m;
      let daydiff = nowDay -d;
      //console.log("agediff:"+agediff+"monthdiff:"+monthdiff+"daydiff:"+daydiff);
      if(monthdiff<0 && daydiff<0){
        agediff-=1;
      }
      if(daydiff>0 && monthdiff<0){
        agediff-=1;
      }
      if(daydiff===0 && monthdiff<0){
        agediff-=1;
      }
      if(daydiff<0 && monthdiff===0){
        agediff-=1;
      }
      return agediff;
    }
    else{
      return 0;
    }
  }
  calcAge(dob) {
    if (dob) {
      const y = parseInt((dob).slice(0, 4));
      const m = parseInt((dob).slice(5, 7));
      const d = parseInt((dob).slice(8, 10));
      const now = new Date();
      const nowMonth = now.getMonth()+1;
      const nowDay = now.getDate();
      const nowYear = now.getFullYear();
      let agediff= nowYear-y;
      let monthdiff = nowMonth - m;
      let daydiff = nowDay - d;
      if((nowMonth - m)<=0){
        monthdiff = m -nowMonth;
        if(monthdiff===6){
          if((d-nowDay)<0){
            monthdiff=5;
          }
          if((d-nowDay)>0){
            monthdiff=7;
          }
          if((d-nowDay)===0){
            monthdiff=7;
            //console.log("836:"+monthdiff);
          }
        }
        if(monthdiff>6){
          agediff-=1;
        }
      }
      if((nowMonth - m)>0)
      {
        monthdiff=nowMonth - m;
        if(monthdiff>=6)
        {
          if(daydiff<0){
            monthdiff=monthdiff-1;
          }
        }
        if(monthdiff>=6){
          agediff+=1;
        }
      }
      return agediff;
    }
    else{
      return 0;
    }
  }
  
  getUL() {
    //return 'http://localhost:3999/home/GetQouteApi?channel=';
    return 'http://40.71.175.92:8111/home/GetQouteApi?channel=';
  }
  getULEncrypted() {
    //return 'http://localhost:3999/home/GetQouteApiEncrypted?Context=';
    //return 'http://40.71.175.92:8111/home/GetQouteApiEncrypted?Context=';
    //return 'http://foresters.iidemo.com/mobileservice/home/GetQouteApiEncrypted?Context=';
    //return 'https://quotes.foresters.com/home/GetQouteApiEncrypted?Context=';
    return this.apiurl+'/home/GetQouteApiEncrypted?Context=';
  }

  getUL1() {
    //return 'http://localhost:3999/home/GetSingleQouteApi?channel=';
    return 'http://40.71.175.92:8111/home/GetSingleQouteApi?channel=';
  }
  getUL1Encrypted() {
    //return 'http://localhost:3999/home/GetSingleQouteApiEncrypted?Context=';
    //return 'http://40.71.175.92:8111/home/GetSingleQouteApiEncrypted?Context=';
    //return 'http://foresters.iidemo.com/mobileservice/home/GetSingleQouteApiEncrypted?Context=';
    //return 'https://quotes.foresters.com/home/GetSingleQouteApiEncrypted?Context='
    return this.apiurl+'/home/GetSingleQouteApiEncrypted?Context='
  }

  getUlEm() {
    //return 'http://localhost:3999/home/sendSingleQoute?';
    return 'http://40.71.175.92:8111/home/sendSingleQoute?';
  }
  getUlEmEncrypted() {
    //return 'http://localhost:3999/home/sendSingleQouteEncrypted?Context=';
    //return 'http://40.71.175.92:8111/home/sendSingleQouteEncrypted?Context=';
    //return 'http://foresters.iidemo.com/mobileservice/home/sendSingleQouteEncrypted?Context=';
    //return 'https://quotes.foresters.com/home/sendSingleQouteEncrypted?Context=';
    return this.apiurl+'/home/sendSingleQouteEncrypted?Context=';
  }
  getJuvEmEncrypted() {
    //return 'http://localhost:3999/home/sendSingleQouteJuvApiEncrypted?Context=';
    //return 'http://40.71.175.92:8111/home/sendSingleQouteJuvApiEncrypted?Context=';
    //return 'http://foresters.iidemo.com/mobileservice/home/sendSingleQouteJuvApiEncrypted?Context=';
    //return 'https://quotes.foresters.com/home/sendSingleQouteJuvApiEncrypted?Context=';
    return this.apiurl+'/home/sendSingleQouteJuvApiEncrypted?Context=';
  }
  getJuveEncrypted() {
    //return 'http://localhost:3999/home/GetSingleQouteJuvApi?channel=';    
    //return 'http://40.71.175.92:8111/home/GetSingleQouteJuvApiEncrypted?Context=';
    //return 'http://foresters.iidemo.com/mobileservice/home/GetSingleQouteJuvApiEncrypted?Context=';
    //return 'https://quotes.foresters.com/home/GetSingleQouteJuvApiEncrypted?Context=';
    return this.apiurl+'/home/GetSingleQouteJuvApiEncrypted?Context=';
  }

  getJuvEm() {
    //return 'http://localhost:3999/home/sendSingleQouteJuvApiEncrypted?Context=';
    //return 'http://40.71.175.92:8111/home/sendSingleQouteJuvApiEncrypted?Context=';
    //return 'http://foresters.iidemo.com/mobileservice/home/sendSingleQouteJuvApiEncrypted?Context=';
    //return 'https://quotes.foresters.com/home/sendSingleQouteJuvApiEncrypted?Context=';
    return this.apiurl+'/home/sendSingleQouteJuvApiEncrypted?Context=';
  }

  getJuve() {
    //return 'http://localhost:3999/home/GetSingleQouteJuvApiEncrypted?Context=';
    //return 'http://40.71.175.92:8111/home/GetSingleQouteJuvApiEncrypted?Context=';
    //return 'http://foresters.iidemo.com/mobileservice/home/GetSingleQouteJuvApiEncrypted?Context=';
    //return 'https://quotes.foresters.com/home/GetSingleQouteJuvApiEncrypted?Context='
    return this.apiurl+'/home/GetSingleQouteJuvApiEncrypted?Context='
  }
  getAuthEncrypted() {
    //return 'http://localhost:3999/home/ValidateLoginEncrypted?Context=';
    //return 'http://40.71.175.92:8111/home/ValidateLoginEncrypted?Context=';
    //return 'http://foresters.iidemo.com/mobileservice/home/ValidateLoginEncrypted?Context=';
    //return 'https://quotes.foresters.com/home/ValidateLoginEncrypted?Context=';
    return this.apiurl+'/home/ValidateLoginEncrypted?Context=';
  }

  validateTokenEncrypted() {
    //return 'http://localhost:3999/home/GetUserStatusEncrypted?Context=';
    //return 'http://40.71.175.92:8111/home/GetUserStatusEncrypted?Context=';
    //return 'http://foresters.iidemo.com/mobileservice/home/GetUserStatusEncrypted?Context=';
    //return 'https://quotes.foresters.com/home/GetUserStatusEncrypted?Context=';
    return this.apiurl+'/home/GetUserStatusEncrypted?Context=';
  }

  validateToken() {
    //return 'http://localhost:3999/home/GetUserStatus?ActivationCode=';
    //return 'http://40.71.175.92:8111/home/GetUserStatus?ActivationCode=';
    //return 'http://foresters.iidemo.com/mobileservice/home/GetUserStatus?ActivationCode=';
    //return 'https://quotes.foresters.com/home/GetUserStatus?ActivationCode=';
    return this.apiurl+'/home/GetUserStatus?ActivationCode=';
  }

  postDeviceToken() {
    //return 'http://localhost:3999/home/DeveiceTokenEncrypted?Token=';
    //return 'http://40.71.175.92:8111/home/DeveiceTokenEncrypted?Token=';
    //return 'http://foresters.iidemo.com/mobileservice/home/DeveiceTokenEncrypted?Token=';
    //return 'https://quotes.foresters.com/home/DeveiceTokenEncrypted?Token=';
    return this.apiurl+'/home/DeveiceToken?Token=';
  }
  postDeviceTokenEncrypted() {
    //return 'http://localhost:3999/home/DeveiceTokenEncrypted?Context=';
    //return 'http://40.71.175.92:8111/home/DeveiceTokenEncrypted?Context=';
    //return 'http://foresters.iidemo.com/mobileservice/home/DeveiceTokenEncrypted?Context=';
    //return 'https://quotes.foresters.com/home/DeveiceTokenEncrypted?Context=';
    return this.apiurl+'/home/DeveiceTokenEncrypted?Context=';
  }
  getLandingPageEncrypted(){
    //return 'http://foresters.iidemo.com/mobileservice/home/GetHomepageResourcesApiEncrypted?Context=';
    //return 'https://quotes.foresters.com/home/DeveiceTokenEncrypted?Context=';
    return this.apiurl+'/home/GetHomepageResourcesApiEncrypted?Context=';
  }
  getProductsInfoApiEncrypted(){
    //return 'http://foresters.iidemo.com/mobileservice/home/GetProductsInfoApiEncrypted?Context=';
    //return 'https://quotes.foresters.com/home/GetProductsInfoApiEncrypted?Context=';
    return this.apiurl+'/home/GetProductsInfoApiEncrypted?Context=';
  }
  getresourceInfoApiEncrypted(){
    //return 'http://foresters.iidemo.com/mobileservice/home/GetResourcesApiEncrypted?Context=';
    //return 'https://quotes.foresters.com/home/GetResourcesApiEncrypted?Context=';
    return this.apiurl+'/home/GetResourcesApiEncrypted?Context=';
  }
  getChannelSubchannel(){
    //return 'http://foresters.iidemo.com/mobileservice/home/GetResourcesApiEncrypted?Context=';
    //return 'https://quotes.foresters.com/home/GetResourcesApiEncrypted?Context=';
    return this.apiurl+'/home/GetAllChannelsSubChannels';
  }
  getHomePageUpdate(){
    return this.apiurl+'/home/UpdateHomepageResources?Context=';
  }
  getViewReport(){
    return  this.apiurl+'/home/GenerateReportLinkEncrypted?Context=';
  }
  getJuvViewReport(){
    return  this.apiurl+'/home/GenerateJuvReportLinkEncrypted?Context=';
  }
  UpdateVersion(){
    return this.apiurl+'/home/UpdateAppVersion?';
  }
  getCurrentVersion(){
    return this.apiurl+'/home/GetAppVersion?appName=Foresters Mobile Quote';
  }
  sendPushNotification(){
    return this.apiurl+'/home/SendNotificationsFromWeb?'
  }
  setQdD(dt) {
    if(dt.name)
    {
      this.qdDNm = dt.name;
      this.qdDPl = dt.pl;
      this.qdDAm = dt.amn;
      this.qdDAm1 = dt.amount1;
      this.e=dt.e;
    }else{
      this.qdXt = dt;
    }
  }
  getQdD() {
    if(this.qdDNm){
    const dta = {
      name: this.qdDNm,
      plan: this.qdDPl,
      amount: this.qdDAm,
      amount1:this.qdDAm1,
      e:this.e
    };
    return dta;}
    else{
      return this.qdXt;
    }
  }
  getStatQD() {
    //console.log("inside dataservice getStatQD: "+this.dX94.dob+" "+this.dX94.city+" "+this.dX94.amount+" "+this.qdDNm+" "+this.qdDPl+ " "+this.qdDAm);
    if (this.dX94) {
      //console.log("inside if loop")
      if (this.dX94.dob && this.dX94.city && this.dX94.amount && this.qdDNm && this.qdDPl && this.qdDAm) {
        //console.log("inside if if loop")
        return true;
      }
      return false;
    } else {
      //console.log("inside else loop")
      return false;
    }
  }
  getLan() {
      this._dKwStr23.get('Language').then ( (val)=>{
      this.lan=val;
      return this.lan;
    })
    if (!this.lan) {
      this.setLan('eng');
    }
    return this.lan;
  }
  
  getState() {
      this._dKwStr23.get('State').then ( (val)=>{
      this.State=val;
      return this.State;
    })
    if (!this.State) {
      this.setState('2');
    }
    return this.State;
  }
  setState(st){
    this.State =st;
  }
  setLan(l) {
    this.lan = l;
  }
  setQdOpD(abc){
    this.qdOpData = abc;
  }
  getQdOpD(){
    return this.qdOpData;
  }
  async presentToastWithOptions(msg, pg) {
    const toast = await this.toastController.create({
      header: msg,
      message: pg,
      position: 'bottom',
      buttons: [
        {
          icon: 'close',
          role: 'cancel'
        }
      ],
      duration: 2500
    });
    toast.present();
  }
  async presentToastWithOptionsforInternet(msg, pg) {
    const toast = await this.toastController.create({
      header: msg,
      message: pg,
      position: 'bottom',
      buttons: [
        {
          icon: 'close',
          role: 'cancel'
        }
      ],
      duration: 10000
    });
    toast.present();
  }
  rate(namee) :[string]
  {
    //private pNL = ['Advantage Plus II', 'Strong Foundation', 'PlanRight', 'Your Term', 'SMART UL'];
    //['Non-Tobacco','Tobacco','Tobacco Plus','Non-Tobaco Plus','Preferred Non-Tobacco','Preferred Plus Non-Tobaco','Juvenile']
    const pr = this.pNL;
    let c:any = [];
    const rt = this.getDataToRateClassPopover() ;    
    if(this.dX94 !== false || this.dX94){
      const pt = this.dX94.prodTyp;
      if(pt === "0"){
          c.push(rt[0]);
          c.push(rt[1]);
          return c;
      } 
      else if(pt === "1"){
        if(namee === pr[2]){
          c.push(rt[0]);
          c.push(rt[1]);
          return c;
        } else if(namee === pr[1]){
          return null;
        }
       else if(namee === pr[0] || namee === pr[3] || namee === pr[4]) {
        c.push(rt[0]);
        c.push(rt[1]);
        c.push(rt[2]);
        c.push(rt[3]);
        c.push(rt[4]);
        c.push(rt[5]);
        return c;
        }
      }
    } else{
      return null;
    }
  }
  addDB_X682(temp): Promise<any>{
    return this._dKwStr23.get(dXStrKY).then((eXv) => {
      if(eXv){
          eXv.push(temp);
          //console.log("entered1:"+temp);
          return this._dKwStr23.set(dXStrKY, eXv);
      } else {
        //console.log("entered2:"+temp);
        return this._dKwStr23.set(dXStrKY,[temp]);
      }

    });
    //console.log("entered3:"+temp);
  }

  getDB_X682(): Promise<any>{
    return this._dKwStr23.get(dXStrKY);
  }

  updateDB_X682(trXd): Promise<any>{
    return this._dKwStr23.get(dXStrKY).then((eXv: any) => {
      if(!eXv  || eXv.length === 0){
        return null;
      }
      let nXi = [];
      
      for(let i of eXv){
        if(i.id === trXd.id){
          nXi.push(trXd);
        } else {
          nXi.push(i);
        }
      }
      return this._dKwStr23.set(dXStrKY, nXi);
    });
  }
  deleteDB_X682(id): Promise<any>{
    return this._dKwStr23.get(dXStrKY).then((eXv:any) => {
      if(!eXv || eXv.length == 0){
        return null;
      }
      
      let tXK: any = [];
      for(let i of eXv){
        if(i.id !== id){
          tXK.push(i);
        }
      }
      return this._dKwStr23.set(dXStrKY, tXK);
    });
  }

  setModeEntery(eee, nav) {
    this.modeEntery = eee;
    /*this.navCtrl.navigateRoot('/temp-direct').then(e => {
      this.navCtrl.navigateForward(nav)
    });*/
    this.navCtrl.navigateForward(nav)
  }
  getModeEntery() {
    if (this.modeEntery) {
      return this.modeEntery;
    } else {
      this.modeEntery = 'sp';
      return this.modeEntery;
    }
  }
  setvalue(agentkey, agentvalue)
  {
    this.agentkey=agentkey;
    this.agentvalue=agentvalue;
    this._dKwStr23.set(this.agentkey,this.agentvalue);
	}
  getvalue(agentkey)
  {
    this.agentkey=agentkey;
    this._dKwStr23.get(this.agentkey).then ( (val)=>{
      this.agentvalue=val;
      this.agentvalue=this.agentvalue;
      //console.log('activation code:'+val);
      return this.agentvalue;
    })
  }
  setActivationCode(activationcode){
    this.activationcodeval=activationcode;
  }
  getActivationCode(){
    return this.activationcodeval;
  }
  setHome(home){
    this.home=home;
  }
  getHome(){
    this._dKwStr23.get('Landing').then ( (val)=>{
      this.home=val;
      //console.log('returning value in first return:'+this.home);
      return this.home;
    })
    if (!this.home) {
      this.setHome('/client-basic-input');
    }
    //console.log('returning value in second return:'+this.home);
    return this.home;
  }
  currencyconverter(anyvalue, solvetype)
  {
    this.numconvertcurrency = anyvalue;
    if(this.numconvertcurrency!=null){
      this.numconvertcurrency=this.numconvertcurrency.replace('$','');
      //console.log(this.numconvertcurrency);
      if(isNaN(this.numconvertcurrency)===false){
        this.numconvertcurrency = Number(this.numconvertcurrency);
		if(solvetype==='face'){
        this.numconvertcurrency = this.currencyPipe.transform(this.numconvertcurrency,'USD','symbol', '1.0-0');
		}
		else{
			this.numconvertcurrency = this.currencyPipe.transform(this.numconvertcurrency,'USD','symbol', '1.2-2');
			}
		
      }
    }
    return this.numconvertcurrency;
  }
  currencyconverteralt(anyvalue, solvetype)
  {
    this.numconvertcurrency = anyvalue;
    //console.log(anyvalue+" solve type:"+solvetype+"Is not a number"+isNaN(Number(anyvalue)));
    if(anyvalue!=null){
      if(isNaN(anyvalue)===false)
      {
        anyvalue = Number(anyvalue);
        if(solvetype==='face'){
          this.numconvertcurrency = this.currencyPipe.transform(anyvalue,'USD','symbol', '1.2-2');
        }
        else{
          this.numconvertcurrency = this.currencyPipe.transform(anyvalue,'USD','symbol', '1.0-0');
        }
      }
      else{
        if(anyvalue.includes('$'))
          anyvalue=anyvalue.replace('$','');
        if(anyvalue.includes(','))
          anyvalue=anyvalue.replace(',','');
        if(anyvalue.includes(' '))
          anyvalue=anyvalue.replace(' ','');
        //console.log(anyvalue+" solve type:"+solvetype+"Is not a number"+isNaN(Number(anyvalue)));
        if(isNaN(anyvalue)===false){
          anyvalue = Number(anyvalue);
            if(solvetype==='face'){
                this.numconvertcurrency = this.currencyPipe.transform(anyvalue,'USD','symbol', '1.2-2');
            }
            else{
                this.numconvertcurrency = this.currencyPipe.transform(anyvalue,'USD','symbol', '1.0-0');
            }
        }
      }
    }
    return this.numconvertcurrency;
  }
  async validateactivationcode() {
    this._dKwStr23.get('activation code').then((val) => {
      this.activationcode = val;
      this.activationcode = this.activationcode;
      this._dKwStr23.get('expirydate').then((val1)=>{
        this.expiryDate=new Date(val1);
        if(this.determineexpiry(this.expiryDate)===true){
          this._dKwStr23.remove('activation code');
          this.navCtrl.navigateRoot('/login');
        }
        //console.log(this.activationcode);
        if (this.activationcode === null) {
          //this.navCtrl.navigateRoot('/home/login');
          this.navCtrl.navigateRoot('/login');
        }
        if (this.activationcode != null) {
          //this.activationcode = this.DecryptReturnVal(this.activationcode);
          //console.log(this.activationcode);
          this.validatetokenurl = this.validateTokenEncrypted();
          //console.log(encodeURIComponent(this.activationcode));
          //console.log(this.validatetokenurl +  encodeURIComponent(this.activationcode));
          this.http.get(this.validatetokenurl +  encodeURIComponent(this.activationcode)).subscribe(data => {
            //console.log(data);
            this.tokenvalidity = this.DecryptReturnVal(data);
            //console.log(this.tokenvalidity);
            //console.log(this.tokenvalidity.ActivationStatus);
            if (this.tokenvalidity.ActivationStatus === 'InActive' || this.tokenvalidity.ActivationStatus === 'InvalidLogin') {
              this._dKwStr23.remove('activation code');
              this.navCtrl.navigateRoot('/login');
            }
          })
        }
      })
    })
  }
  getproductdesc(name, language){
    //console.log("name:"+name.trim()+"language:"+ language.quotecomparepage.p4)
    if(name.toUpperCase().trim() ===language.quotecomparepage.p1.toUpperCase().trim()){
      //return 'True North Plus';
      return 'Advantage Plus II - Par Whole Life';
    }
    if(name.toUpperCase().trim() ===language.quotecomparepage.p5.toUpperCase().trim()){
      //return 'True Foundation';
      return 'Strong Foundation - Non-Med Term';
    }
    if(name.toUpperCase().trim() ===language.quotecomparepage.p2.toUpperCase().trim()){
      //return 'Smart Plan';
      return 'PlanRight - Final Expense Whole Life';
    }
    if(name.toUpperCase().trim() ===language.quotecomparepage.p4.toUpperCase().trim()){
      //return 'Essential Term';
      //console.log("true")
      return 'Your Term – Med, Non-Med Term';
    }
    if(name.toUpperCase().trim() ===language.quotecomparepage.p3.toUpperCase().trim()){
      //return 'Prime UL';
      return 'SMART UL - Current Assumption UL';
    }
    if(name.toUpperCase().trim() ===language.quotecomparepage.p6.toUpperCase().trim()){
      //return 'Prime UL';
      return 'BrightFuture - Juvenile Whole Life';
    }
    if(name.toUpperCase().trim() ===language.quotecomparepage.p7.toUpperCase().trim()){
      //return 'Prime UL';
      return 'Prepared II - Accidental Death Term';
    }
    
  }
  createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  }
  createinternetmsg(status,langinput)
  {
    /*this.network.onDisconnect().subscribe(async() => {
      const alert = await this.alertController.create({
              header: 'Are you connected to internet through Data or Wifi',
              message: 'Check Your Connection?',
              buttons:  ['OK']
              
            });
      
            await alert.present();
      });*/
    if(langinput==='eng')
    {
      if(status===false)
      {
        this.presentToastWithOptionsforInternet('The app requires internet connection in order to function properly. Please check your internet connectivity', '');
      }
    }
    if(langinput ==='frn'){
      if(status===false)
      {
      this.presentToastWithOptionsforInternet("L'application nécessite une connexion Internet pour fonctionner correctement. Veuillez vérifier votre connectivité Internet", '');
      }
    }
  }
  
  isNumber(number)
  {
	let re = /\,/gi;
    if(number!=null){
      number=number.replace('$','');
      number = number.replace(re, "");
    }
    //console.log("amount:"+number+" " +isNaN(number));
    if(isNaN(number)===false){
      number = Number(number);
      //console.log("number1400:"+number+" " +isNaN(number));
    }
    return isNaN(number);
  }
  isMaxNumber(number){
    //console.log("amount1:"+number);
    if(number===null){
      return true;
    }
    if(number!=null){
      number=number.replace('$','');
      number=number.replace(',','');
    }
    //console.log("amount1:"+number+" " +isNaN(number));
    if(isNaN(number)===false){
      //console.log("number1413:"+number+" " +isNaN(number));
      number = Number(number);
      if(number===0)
      {
        return true;
      }
      
    }

    return isNaN(number);
  }
  Encrypt(sVal) {
    var key = CryptoJS.enc.Utf8.parse(this.secretkey);
    var iv = CryptoJS.enc.Utf8.parse(this.secretkey);
    var sRet = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(sVal), key,
        {
        keySize: 128 / 8,
            iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    //return encodeURI(String(sRet));
    return encodeURIComponent(String(sRet));
  }

  DecryptReturnVal(Data)
  {
    var text = decodeURIComponent(Data);
    //console.log("1584:"+text);
    var key = CryptoJS.enc.Utf8.parse(this.secretkey);
    var iv = CryptoJS.enc.Utf8.parse(this.secretkey);
    var decrypted = CryptoJS.AES.decrypt(text, key, {
        keySize: 128 / 8, iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7
    });
    var myText = decrypted.toString(CryptoJS.enc.Utf8);
    //console.log("myText:"+myText);
    var myObj = JSON.parse(myText);
    //console.log(myObj);
    return myObj;
  }
  GetRateClassDetails(producttype, age){
    //console.log("producttype:"+producttype);
   // producttype=producttype
    var c:any = [];
    //mpDXRtV = [0,1,5,4,3,2];
    const rt = this.getDataToRateClassPopover() ; 
    //console.log("1367:"+rt);  
    //console.log("1368:"+this.mpDXRtV);
    if(age>=this.juve.getJA()+1)
    {
      if(producttype==="0"){
        return[{id: this.mpDXRtV[0], name: rt[0]},
          {id: this.mpDXRtV[1], name: rt[1]}];
      }
      if(producttype==="1"){
      return[{id: this.mpDXRtV[0], name: rt[0]},
          {id: this.mpDXRtV[1], name: rt[1]},
          {id: this.mpDXRtV[2], name: rt[2]},
          {id: this.mpDXRtV[3], name: rt[3]},
          {id: this.mpDXRtV[4], name: rt[4]},
          {id: this.mpDXRtV[5], name: rt[5]}];
      }
    }
    else{
      return[{id: this.mpDXRtV[6], name: rt[6]}];
    }
  }
  GetRateClassDetails1(producttype, age, productname){
    //console.log("producttype:"+producttype);
   // producttype=producttype
   //private pNL = ['Advantage Plus II', 'Strong Foundation', 'PlanRight', 'Your Term', 'SMART UL', 'BrightFuture', 'Prepared Accidental Death',];
    var c:any = [];
    //mpDXRtV = [0,1,5,4,3,2];
    const rt = this.getDataToRateClassPopover() ; 
    //console.log("1367:"+rt);  
    //console.log("1368:"+this.mpDXRtV);
    if(productname===this.pNL[2]){
      return[{id: this.mpDXRtV[0], name: rt[0]},
            {id: this.mpDXRtV[1], name: rt[1]}];
    }
    else
    {
      if(age>=this.juve.getJA()+1)
      {
        if(producttype==="0"){
          return[{id: this.mpDXRtV[0], name: rt[0]},
            {id: this.mpDXRtV[1], name: rt[1]}];
        }
        if(producttype==="1"){
        return[{id: this.mpDXRtV[0], name: rt[0]},
            {id: this.mpDXRtV[1], name: rt[1]},
            {id: this.mpDXRtV[2], name: rt[2]},
            {id: this.mpDXRtV[3], name: rt[3]},
            {id: this.mpDXRtV[4], name: rt[4]},
            {id: this.mpDXRtV[5], name: rt[5]}];
        }
      }
      else{
        return[{id: this.mpDXRtV[6], name: rt[6]}];
      }
    }
  }
  GetRateClassName(rateclassval){
    //console.log("line 1482:"+rateclassval+":isNaN:"+isNaN(rateclassval));
    var rt = this.getDataToRateClassPopover() ;
    var rtval = this.mpDXRtV;
    for(let i=0;i<rtval.length;i++)
    {
      //console.log("inside for loop rtVal:"+rtval[i]+"rateclassval:"+rateclassval+"rt:"+rt[i]);
      if(rtval[i] === Number(rateclassval)){
         return rt[i];
      }
    }
  }
  getProductType(productname, producttype, langobject){
   // console.log("productname,"+productname+" producttype, "+producttype+ " langobject, "+langobject);
    if(this.getproductdesc(productname, langobject)!=langobject.quotecomparepage.p2 && this.getproductdesc(productname, langobject)!=langobject.quotecomparepage.p7){
      if (producttype) {
        if (producttype === '0') {
          producttype= langobject.producttype.nonmed;
        } else if (producttype === '1') {
          producttype= langobject.producttype.med;
        }
      }
    }
    else{
      producttype= langobject.producttype.notapplicable;
    }
    return producttype;
  }
  sendEmailIndicator(productName){
    var emailindicator=false;
    //private pNL = ['Advantage Plus II', 'Strong Foundation', 'PlanRight', 'Your Term', 'SMART UL', 'BrightFuture', 'Prepared Accidental Death',];
    if(productName.toUpperCase() ==this.pNL[1].toUpperCase() || productName.toUpperCase()==this.pNL[2].toUpperCase() || productName.toUpperCase()==this.pNL[3].toUpperCase() || productName.toUpperCase()==this.pNL[5].toUpperCase() || productName.toUpperCase()==this.pNL[6].toUpperCase()){
      emailindicator = true; 
    }
    return emailindicator;
  }
  getPayeeType(freq,dLan1){
    if(freq){
      if(freq==='Monthly'){
        return dLan1.monthly;
      }
      if(freq==='Annual'){
        return dLan1.annually;
      }
    }
  }
  getGCVRPUCIDIV(productName){
    if(productName.toUpperCase() ==="PLANRIGHT"|| productName.toUpperCase() ==="BRIGHTFUTURE"){
      return true; 
    }
    return false;
  }
  getGender(langOpt, gen){
    if (gen === 'Male') {
      return langOpt.male;
    } else if (gen === 'Female') {
      return langOpt.female;
    }
    if (gen === 'Male') {
      return langOpt.male;
    } else if (gen === 'Female') {
      return langOpt.female;
    }
    if (gen === "0") {
      return langOpt.male;;
    } else if (gen === "1") {
      return langOpt.female;
    }
  }
  getSolve(solve,dLan1){
    if (solve === 'prem') {
      return dLan1.solve.t1;
    } else if (solve === 'face') {
      return dLan1.solve.t2;
    }
  }
  setRPUGCVCIDI(gcvrpucidi){
    this.gcvrpucidi=gcvrpucidi;
  }
  getRPUGCVCIDI(){
    return this.gcvrpucidi;
  }
  getJuvenileAgeOptionList(){
    var juveage=this.juve.getJA();
    var agelist=[];
    for(let i=this.juve.getminJA(); i<=juveage; i++){
      agelist.push(i);
    }
    return agelist;
  }
  calcBirthYear(age){
    const now = new Date();
    const nowMonth = now.getMonth()+1;
    const nowDay = now.getDate();
    const nowYear = now.getFullYear();
    var birthyear = nowYear-age;
    let date: Date = new Date(birthyear+'/'+nowMonth+'/'+nowDay);
    return (this.datepipe.transform(date, 'yyyy-MM-dd'));
  }
  expirydate(days){
    var result = new Date();
    result.setDate(result.getDate() + days);
    return result;
  }
  determineexpiry(date){
    var currentdate = new Date();
    var currentdateformat = new Date(currentdate.getMonth()+1 +'/'+currentdate.getDate()+'/'+currentdate.getFullYear());
    var expirydateformat = new Date(date.getMonth()+1 +'/'+date.getDate()+'/'+date.getFullYear());
    //console.log("result date:"+currentdateformat);
    //console.log("expiry date:"+expirydateformat);
    const diffTime = expirydateformat.getTime()-currentdateformat.getTime();
    //console.log(diffTime);
    //const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    if(diffTime>0)
      return false;
    else
      return true;
  }
  setJuvenileData(juvearr){
    this.juvedetailsjson=juvearr;
  }
  getJuvenileData(){
    return this.juvedetailsjson;
  }
  getJuvenileRateClass(){
    return this.mpDXRtV[6];
  }
  setQconlyProduct(qcOnlyJson){
    this.qcOnlyJson=qcOnlyJson;
  }
  getQconlyProduct(){
    return this.qcOnlyJson;
  }
  getcontentstyle(){
    if(this.platform.is('ios')){
      return "content_short_lenght";
    }
    else{
      return "content";
    }
  }
  getPlatform(){
    if(this.platform.is('ios')){
      return "ios";
    }
    else if(this.platform.is('android')){
      return "android";
    }
    else{
      return "others";
    }
  }
  setNavPath(path){
    this.navpath=path;
  }
  getNavPath(){
    return this.navpath;
  }
  setRouterTabStyle(style){
    this.routertabstyle=style;
  }
  getRouterTabStyle(){
    return this.routertabstyle;
  }
  isEmail(search, medium):boolean
  {
    var  serchfind= false;
    var emailsearch=[]
    var email=[];
    if(medium==='Email'){
      if(search)
      {
        email=search.split(',');
        //console.log(email);
        for(let i=0;i<email.length;i++){
          if(EmailValidator.validate(email[i].trim()))
          {
            emailsearch.push(true);
          }
        }
        //console.log(emailsearch);
        if(emailsearch.length===email.length){
          serchfind=true;
        }
      }
    }
    if(medium==='Text'){
      if(search)
      {
        search=Number(search);
        if(!isNaN(search)){
          if(String(search).length ===10){
            if(String(search).charAt(0)!='0'){
              serchfind = true;
            }
          }
        }
      }
    }
    return serchfind
  }
  getMinDate(){
    const now = new Date();
    const nowYear = now.getFullYear();
    var MaxAge=85;
    if(this.getData().specialquoteind==='Yes')
      MaxAge=99;
    now.setDate(now.getDate()-(MaxAge*365+20))
    return (this.datepipe.transform(now,'yyyy-MM-dd'));
  }
  getToday(){
    const now = new Date();
    now.setDate(now.getDate())
    return(this.datepipe.transform(now,'yyyy-MM-dd'))
  }
  getAgeRange(object){
    var range;
    var age;
    if(this.getData().specialquoteind==='Yes')
    {
      range={
        upper:99,
        lower:0,
        msgeng:'Please enter an age >=0 year and <= 99 years. Not all products are available for all ages',
        msgfr:'Veuillez saisir un capital assuré entre <=99'
      }
    }
    else{
      range={
        upper:85,
        lower:0,
        msgeng:'Please enter an age >=0 year and <= 85 years. Not all products are available for all ages',
        msgfr:'Veuillez saisir un capital assuré entre <=85'
      }
    }
    age = this.calcLastAge(object.getData().dob)
    if(age<range.lower || age>range.upper){
      return range.msgeng;
    }
    if(age>=range.lower && age<=range.upper){
      return 'Valid Range';
    }
  }
  getFacePremRange(producttype, solvetype, freq){
    var range;
    if(Number(producttype)===1 && solvetype==='prem'){
      range={
        upper:10000000,
        lower:5000,
        msgeng:'Please Enter a Face Amount between $5,000 & $10,000,000',
        msgfr:'Veuillez saisir un capital assuré entre $5,000 & $10,000,000'
      }
    }
    if(Number(producttype)===0 && solvetype==='prem'){
      range={
        upper:500000,
        lower:5000,
        msgeng:'Please Enter a Face Amount between $5,000 & $500,000',
        msgfr:'Veuillez saisir un capital assuré entre $5,000 & $500,000'
      }
    }
    if(solvetype==='face' && freq==='Monthly'){
      range={
        upper:400000,
        lower:10,
        msgeng:'Please Enter a Premium Amount >$10',
        msgfr:'Veuillez saisir un montant de prime >$10'

      }
    }
    if(solvetype==='face' && freq==='Annual'){
      range={
        upper:4000000,
        lower:120,
        msgeng:'Please Enter a Premium Amount >$120',
        msgfr:'Veuillez saisir un montant de prime >$120'
      }
    }
    return range;
  }
  validateRange(object){
    if(object.getInpputDolr()){
      if(object.getData().solve==='prem'){
        //console.log("range:"+this.getFacePremRange(object.getData().prodTyp,object.getData().solve,object.getData().freq).upper);
        if(object.getData().amount>=this.getFacePremRange(object.getData().prodTyp,object.getData().solve,object.getData().freq).lower && object.getData().amount<=this.getFacePremRange(object.getData().prodTyp,object.getData().solve,object.getData().freq).upper){
          return true;
        }
      }
      if(object.getData().solve==='face'){
        if(Number(object.getData().amount)>=this.getFacePremRange(object.getData().prodTyp,object.getData().solve,object.getData().freq).lower){
          return true;
        }
      }
    }
    return true;
  }
  getXThings(object) {
    var xThings = [];
    if (xThings.length <= 0) {
      if (!object.getInpputSt()) {
        if(object.getLan()==='eng'){
          xThings.push('State');
        }
        if(object.getLan()==='frn'){
          xThings.push('l\' État');
        }
      }
      if (!object.getInpputDob()){
        if(object.getLan()==='eng'){
          xThings.push('Date of Birth');
        }
        if(object.getLan()==='frn'){
          xThings.push('Date de Naissance');
        }
      }
      if (!object.getInpputDolr()){
        if(object.getLan()==='eng'){
          xThings.push('Face Amount/Premium');
        }
        if(object.getLan()==='frn'){
          xThings.push('Montant de protection/Prime mensuelle');
        }
      }
      if(object.getInpputDolr()){
        if(object.getData().solve==='prem'){
          if(object.getData().amount<this.getFacePremRange(object.getData().prodTyp,object.getData().solve,object.getData().freq).lower || object.getData().amount>this.getFacePremRange(object.getData().prodTyp,object.getData().solve,object.getData().freq).upper){
            if(object.getLan()==='eng'){
              xThings.push(this.getFacePremRange(object.getData().prodTyp,object.getData().solve,object.getData().freq).msgeng);
            }
            if(object.getLan()==='frn'){
              xThings.push(this.getFacePremRange(object.getData().prodTyp,object.getData().solve,object.getData().freq).msgfr);
            }
          }
        }
        if(object.getData().solve==='face'){
          if(Number(object.getData().amount)<this.getFacePremRange(object.getData().prodTyp,object.getData().solve,object.getData().freq).lower){
            if(object.getLan()==='eng'){
              xThings.push(this.getFacePremRange(object.getData().prodTyp,object.getData().solve,object.getData().freq).msgeng);
            }
            if(object.getLan()==='frn'){
              xThings.push(this.getFacePremRange(object.getData().prodTyp,object.getData().solve,object.getData().freq).msgfr);
            }
          }
        }
      }
      if (object.getInpputDob()){
        if(this.getAgeRange(object)!='Valid Range'){
          xThings.push(this.getAgeRange(object));
        }
      }
    }else{
      xThings = [];
    }
    return xThings;
  }
  getRiderAge(dob, productName, dlan){
    var age;
    var agelabel;
    if(productName.toUpperCase() ==this.pNL[6].toUpperCase() || productName.toUpperCase()==this.pNL[2].toUpperCase()){
      age = this.calcLastAge(dob); 
      agelabel=dlan.agelast;
    }
    else{
      age = this.calcAge(dob);
      agelabel=dlan.aged;
    }
    return {agevalue:age,
            agelabel:agelabel
            };
  }
  openURL(url){
    if(url.toLowerCase().includes("http://")===false && url.toLowerCase().includes("https://")===false){
      url = "http://"+url;
    }
    //console.log(url);
    this.btb.isAvailable().then(isAvailable => {
      if (isAvailable) {
        //console.log("inside if");
        this.btb.openUrl(url);
      } else {
        if(this.platform.is('android')){
          if(url.indexOf(".pdf") !== -1){
            window.open("http://docs.google.com/viewer?url=" +  url + "&embedded=true");
          }else{
            window.open(url);
          }
        }  
      }
    }, error => {
      window.open(url);
      console.log('URL error', error);
    });
  }
  
  public showFingerprintAuthDlg(){
    this.fingerAuth.isAvailable().then(isAvailable => {
      if(isAvailable ==='finger' || isAvailable==='face') {
        this.fingerAuth.show({
          title: 'Biometric Authentication', // (Android Only) | optional | Default: "<APP_NAME> Biometric Sign On"
          subtitle: '', // (Android Only) | optional | Default: null
          description: 'Please authenticate using your finger print', // optional | Default: null
          fallbackButtonTitle: 'Use Device Pin', // optional | When disableBackup is false defaults to "Use Pin".
                                             // When disableBackup is true defaults to "Cancel"
          disableBackup:false,  // optional | default: false
        })
        .then((result: any) => {
          this.menuCtrl.enable(true);
          this.setFingerPrintInd("N");
          this.getDefaultClientDetails();
          /*this._dKwStr23.get('Landing').then ( (val)=>{
            this.navCtrl.navigateRoot(val);
          })
        this.navCtrl.navigateRoot('/home/client-basic-input');*/
        }).catch((error: any) => console.log(error));
      }
      else if(isAvailable) {
        this.fingerAuth.show({
          title: 'Biometric Authentication', // (Android Only) | optional | Default: "<APP_NAME> Biometric Sign On"
          subtitle: '', // (Android Only) | optional | Default: null
          description: 'Please authenticate using your finger print', // optional | Default: null
          fallbackButtonTitle: 'Use Device Pin', // optional | When disableBackup is false defaults to "Use Pin".
                                             // When disableBackup is true defaults to "Cancel"
          disableBackup:false,  // optional | default: false
        })
        .then((result: any) => {
          this.menuCtrl.enable(true);
          this.setFingerPrintInd("N");
          this.getDefaultClientDetails();
        })
        .catch((error: any) => console.log(error));
      } else {
        this.setFingerPrintInd("N");
      }
    }, error => {
      //console.log('isAvaialble error', error);
      this.setFingerPrintInd("N");
      this.menuCtrl.enable(true);
      this.getDefaultClientDetails();
    });
  }
  setFingerPrintInd(ind){
    this.fingerPrintInd=ind;
  }
  getFingerPrintInd(){
    return this.fingerPrintInd;
  }
  setHowMuchInsuranceData(data){
    this.howMuchInsurance=data;
  }
  getHowMuchInsuranceData(){
    return this.howMuchInsurance;
  }
  getDefaultClientDetails(){
    var dob;
    var ageval;
    //console.log(dob);
    var state;
    var stateValue;
    var amount;
    var rateclass;
    var rcvalue;
    var rateclassList;
    var prodTyp = '0';
    var channelcode;
    var subchannelcode;
    var specialquote;
    this._dKwStr23.get('State').then((val)=>{
      state=val;
      if(state===null){
        state='AL'
      }
      this.setInpputSt(true);
      this._dKwStr23.get('StateVal').then ((val)=>{
        stateValue=val;
        this.setInpputDob(true);
        this._dKwStr23.get('PreferredCoverageAmount').then ((val1)=>{
          amount=Number(val1);
          if(!amount){
            amount=100000;
          }
          else if(isNaN(amount)){
            amount=100000;
          }
          else if(amount>500000){
            prodTyp='1'
          }
          this.setInpputDolr(true);
          this._dKwStr23.get('PreferredAge').then ((val2)=>{
            ageval=val2;
            ageval=String(ageval);
            if(!ageval){
              dob=this.calcBirthYear(50);
              rateclass=0;
              rcvalue=0;
              rateclassList=this.GetRateClassDetails('0',this.juve.getJA()+1)
            }
            else if(isNaN(ageval)){
              dob=this.calcBirthYear(50);
              rateclass=0;
              rcvalue=0;
              rateclassList=this.GetRateClassDetails('0',this.juve.getJA()+1)
            }
            else{
              dob=this.calcBirthYear(Number(val2));
              if(Number(ageval)<=this.juve.getJA()){
                rateclass=6;
                rcvalue=6;
                rateclassList=this.GetRateClassDetails('0',this.juve.getJA())
              }
              else{
                rateclass=0;
                rcvalue=0;
                rateclassList=this.GetRateClassDetails('0',this.juve.getJA()+1)
              }
            }
            this._dKwStr23.get('SpecialQuoteInd').then(val=>{
              if(val===null || val==='No'){
                specialquote='No'
              }
              else if(val ==='Yes'){
                specialquote='Yes'
              }
            
              const defaultClient={
                cName: 'Valued Client',
                dob: dob,
                gender: 'Male',
                genVal: 0,
                city: state,
                stateValue: stateValue,
                rateClass: rateclass,
                rcValue: rcvalue,
                prodTyp: prodTyp,
                solve: 'prem',
                amount: amount,
                freq: 'Monthly',
                solvelabel:'Face Amount',
                rateClassList:rateclassList,
                specialquoteind:specialquote
              }
              this.setData(defaultClient);
              this._dKwStr23.get('Landing').then ((valnav)=>{
                //console.log("valnav:"+valnav);
                if(valnav==="quote-details-only-product" || valnav==="juvenile-input-screen"){
                  this._dKwStr23.get('channel').then ( (val)=>{
                    channelcode=val;
                    this._dKwStr23.get('QuerySubChannel').then((val)=>{
                    //this._dKwStr23.get('subchannel').then((val)=>{
                      subchannelcode=val;
                      this.getData().subchannelcode = subchannelcode;
                      var px62 = '';
                      var prod;
                      px62 += '$$OpusPair$$' + 'channel=' + '$$OpusVar$$' + channelcode;
                      px62 += '$$OpusPair$$' + 'subchannel=' + '$$OpusVar$$' + subchannelcode;
                      px62 += '$$OpusPair$$' + 'productType=' + '$$OpusVar$$' + defaultClient.prodTyp;
                      px62 += '$$OpusPair$$' + 'Age=' + '$$OpusVar$$' + this.calcAge(dob);
                      px62 += '$$OpusPair$$' + 'SecondAge=' + '$$OpusVar$$' + this.calcLastAge(dob);
                      px62 += '$$OpusPair$$' + 'RateClass=' + '$$OpusVar$$' + defaultClient.rcValue;
                      px62 += '$$OpusPair$$' + 'Gender=' + '$$OpusVar$$' + defaultClient.genVal;
                      px62 += '$$OpusPair$$' + 'SolveAmount=' + '$$OpusVar$$' + defaultClient.amount;
                      px62 += '$$OpusPair$$' + 'State=' + '$$OpusVar$$' + defaultClient.stateValue;
                      px62 += '$$OpusPair$$' + 'Frequency=' + '$$OpusVar$$' + defaultClient.freq;
                      this.http.get(this.getULEncrypted() + this.Encrypt(px62)).subscribe(data => {
                        prod = this.DecryptReturnVal(data);
                        if(prod){
                          prod = prod.types;
                          let prodtemp=[];
                          for(let i of prod){
                            prodtemp.push(i);
                          }
                          prod=prodtemp;
                        }
                        this._dKwStr23.get("Preferred Product").then((valproduct)=>{
                          var pP; 
                          var nm;
                          var err=null;
                          pP = prod.filter(el => {
                            return el.name === valproduct;
                          });
                          nm=pP[0];
                          this.setQdOpD(nm);
                          this.setQdD(nm);
                          this.navCtrl.navigateRoot(valnav);
                        })
                      });
                    });
                  });
                }
                else {
                  //console.log("inside else if");
                  this.navCtrl.navigateRoot(valnav);
                }
              });
            });
          });
        });
      });
    });
  }
  getUpdateLink(){
    console.log("A2");    
    //1536885388
    if(this.getPlatform()==='ios'){
      return "https://apps.apple.com/us/app/foresters-mobile-quote/id1536885388";
    }
    if(this.getPlatform()==='android'){
      return "https://play.google.com/store/apps/details?id=foresters.illustrateinc.opusmobile.com";
    }
  }
  getDownloadLink(){
    console.log("A3");    
    if(this.getPlatform()==='ios'){
      //return "https://itunes.apple.com/us/app/foresters-biz/id602063944?mt=8";
      return "https://apps.apple.com/us/app/foresters-biz/id602063944";
      //https://apps.apple.com/ca/app/foresters-biz/id602063944
      //return "https://itunes.apple.com/us/app/keynote/id361285480?mt=8";
      //'https://apps.apple.com/ca/app/foresters-biz/id602063944'
    }
    if(this.getPlatform()==='android'){
      return "https://play.google.com/store/apps/details?id=com.infusion.ForestersProducerPortalMobile";
    }
    if(this.getPlatform()==='others'){
      return "https://portal.foresters.biz/"
    }
  }
  getCompanyDisclaimer(){
    return "<div class='companydisclaimer'>Foresters products and riders may not be available or approved in all states and are subject to eligibility requirements, underwriting approval, limitations, contract terms and conditions and state variations. Refer to the applicable Foresters contract for your state for these terms and conditions and ezbiz for product availability. Underwritten by The Independent Order of Foresters.</br></br> Foresters Financial and Foresters are trade names and trademarks of The Independent Order of Foresters (a fraternal benefit society, 789 Don Mills Rd, Toronto, Ontario, Canada M3C 1T9) and its subsidiaries.</div>";
  }
  setOpenGCVRPUCIDIPopover(gcvrupcididetails){
    this.gcvrupcididetails=gcvrupcididetails;
  }
  getOpenGCVRPUCIDIPopover(){
    return this.gcvrupcididetails;
  }
  setNewname(name){
    this.newName=name;
    //console.log("new name: "+name);
  }
  getnewName(){
    return this.newName;
  }
  setagentName(agentName){
    this.agentName=agentName;
    console.log("new name: "+agentName);
  }
  getagentName(){    
    return this.agentName;
  }


  getAdvPlusRiderRuleMsg(productname, dl, t10ind, t20ind, age){
    var msg;
    console.log(this.getproductdesc(productname,dl));
    if(this.getproductdesc(productname,dl)==="Advantage Plus II - Par Whole Life"){
      if(t20ind){
        msg="Term 20 Rider is not applicable for 20 Pay"
      }
      if(t10ind){
        if(age<45 || age>75)
          msg="Term 10 Rider is not applicable for 20 Pay for age less than 45 and greater than 75"
      }
    }
    return msg;
  }
  getCTRoptions(min, max, step){
    var valuelist = []
    for(let i=min;i<=max;i+=step)
    {
      valuelist.push(i);
    }
    return valuelist;
  }

  launchApp(){
    console.log("A1");    
    const options: AppLauncherOptions = {
    }
    if(this.getPlatform()==='ios') {
      this.launchiosapp();
    } 
    else if(this.getPlatform()==='android') {
      options.packageName = 'com.infusion.ForestersProducerPortalMobile';
      this.appLauncher.canLaunch(options).then((canLaunch: boolean) => {
        if (canLaunch) {
          this.appLauncher.launch(options);
        } else {
          alert('ForestersBiz App is NOT installed in your device');
          this.openURL(this.getDownloadLink());
        }
      })
      .catch((error: any) => {console.error('app is not available')
        this.openURL(this.getDownloadLink());
      });
    }
    else{
      options.packageName =  "https://portal.foresters.biz/";
      this.openURL(options.packageName);
    }
  }
  launchiosapp(){
    let app;
    const options: AppLauncherOptions = {
    }
    if (this.platform.is('ios')) {
      //app = 'forestersquote://';
      app='ForestersBiz://'
      options.uri = app;
    } 
    this.appAvailability.check(app).then(
      (yes: boolean) => {
        this.appLauncher.launch(options);
      },
      (no: boolean) => {
        alert('ForestersBiz App is NOT installed in your device');
        this.openURL(this.getDownloadLink());
      }
    );
  }
  setVersionUpdate(data){
    this.versionUpdateDetails = data
  }
  getVersionUpdate(){
    return this.versionUpdateDetails
  }
  LogOut(){
    this._dKwStr23.remove('activation code');
    this._dKwStr23.remove('channel');
    this._dKwStr23.remove('subchannel');
    this._dKwStr23.remove('QuerySubChannel');
    this._dKwStr23.remove('FirstName');
    this._dKwStr23.remove('LastName');
    this._dKwStr23.remove('MiddleName');
    this._dKwStr23.remove('Name');
    this._dKwStr23.remove('State');
    this._dKwStr23.remove('StateVal');
    this._dKwStr23.remove('Status');
    this._dKwStr23.remove('Email');
    this._dKwStr23.remove('theme');
    this._dKwStr23.remove('expirydate');
  }
  validatedate(e)
  {
    var dateformat = /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/;
    // Match the date format through regular expression
    if(e.match(dateformat))
    {
      //Test which seperator is used '/' or '-'
      var opera1 = e.split('/');
      var opera2 = e.split('-');
      var lopera1 = opera1.length;
      var lopera2 = opera2.length;
      // Extract the string into month, date and year
      if (lopera1>1)
      {
        var pdate = e.split('/');
      }
      else if (lopera2>1)
      {
        var pdate = e.split('-');
      }
      var mm  = parseInt(pdate[0]);
      var dd = parseInt(pdate[1]);
      var yy = parseInt(pdate[2]);
      // Create list of days of a month [assume there is no leap year by default]
      var ListofDays = [31,28,31,30,31,30,31,31,30,31,30,31];
      if (mm==1 || mm>2)
      {
        if (dd>ListofDays[mm-1])
        {
          return false;
        }
      }
      if (mm==2)
      {
        var lyear = false;
        if ( (!(yy % 4) && yy % 100) || !(yy % 400)) 
        {
          lyear = true;
        }
        if ((lyear==false) && (dd>=29))
        {
          return false;
        }
        if ((lyear==true) && (dd>29))
        {
          return false;
        }
      }
      if(this.calcLastAge(this.datepipe.transform(e,'yyyy-MM-dd').slice(0,10))<0 || this.calcAge(this.datepipe.transform(e,'yyyy-MM-dd').slice(0,10))<0){
        return false;
      }
    }
    else
    {
      return false;
    }
    return true;
  }
  setResetRider(riderstatus){
    this.riderstatus=riderstatus;
  }
  getResetRider(){
    return this.riderstatus;
  }
}