import { Component, OnInit,ViewChild } from '@angular/core';
import { Platform,NavParams, ModalController,NavController,LoadingController} from '@ionic/angular';
import { CurrencyPipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../services/data.service';
import { HttpClient } from '@angular/common/http';
import { EngLaService } from '../../propertiesFile/eng-la.service';
import { FrnLaService } from '../../propertiesFile/frn-la.service';
//import { Storage} from '@ionic/storage';

@Component({
  selector: 'app-gcvrup-cidi-popover',
  templateUrl: './gcvrup-cidi-popover.page.html',
  styleUrls: ['./gcvrup-cidi-popover.page.scss'],
})
export class GcvrupCidiPopoverPage implements OnInit {
  pL;
  nM;
  rate;
  rateV;
  email;
  textmsg;
  px62;
  langOpt;
  dLan;
  dL1;
  ammnt;
  qDC;
  cName;
  gen;
  age;
  city;
  solve;
  xd24;
  productType;
  paymentmode;
  gcvrpucidi;
  gcvrpuoptions='GCVRPUP';
  gcvcidioptions;
  gcvrpuind=false;
  cidivind = false;
  gcvrpucidiarr;
  adrind=false;
  source;
  constructor(public loadingController: LoadingController,public platform:Platform,private route: ActivatedRoute,/*private navParams: NavParams,*/ private modalController: ModalController, public currencyPipe: CurrencyPipe, private navCtrl :NavController, public dX684: DataService, private http: HttpClient, private en: EngLaService,private fr: FrnLaService) { 
    this.route.queryParams.subscribe(params => {
      this.kroCheck();
      //const xx = this.navParams.get('alphaBateycaly');
      const xx = this.dX684.getOpenGCVRPUCIDIPopover();
      //this.px62 = this.navParams.get('px');
      this.px62 = xx.px;
      //console.log("line 47:"+this.px62);
      this.gcvrpucidi=xx.value;
      if(xx.value==='GCVRPU'){
        this.gcvrpuind=true;
      }
      if(xx.value==='CIDIV'){
        this.cidivind=true;
      }
      this.adrind=xx.adrind;
      this.source=xx.source;
      if(this.adrind===true){
        this.gcvcidioptions='GCVCIDIPADR';
      }
      else{
        this.gcvcidioptions='GCVCIDIP';
      }
      //console.log("this.adrind:"+this.adrind);
      this.nM = xx.nm;
      //console.log(this.nM);
      this.xd24 = this.dX684.getData();
      this.dX684.setData(this.xd24);
      this.cName=this.xd24.cName;
      //console.log("55:"+this.cName);
      this.gen = this.xd24.gender;
      this.city = this.xd24.city;
      this.age = this.dX684.calcAge(this.xd24.dob);
      this.productType= this.dX684.getProductType(this.nM,this.xd24.prodTyp, this.dL1) ;
      this.gen=this.dX684.getGender(this.dLan,this.gen);
      this.paymentmode = this.dX684.getPayeeType(this.xd24.freq,this.dL1)
      this.solve = this.dX684.getSolve(this.xd24.solve,this.dL1);
      this.ammnt = this.currencyPipe.transform(this.xd24.amount, 'USD', 'symbol', '1.0-0');
      this.gcvrpucidiarr=this.dX684.getRPUGCVCIDI();
      if(!this.dX684.getSelectedRateClass()){
        this.rate= this.dX684.GetRateClassName(this.xd24.rateClass);
        console.log("inside if:"+this.rate)
      }
      else{
        this.rate = this.dX684.getSelectedRateClass();
        console.log("in else:"+this.rate);
      }
      this.rateV = this.dX684.fdXRcXV(this.rate);
    });
  }

  ngOnInit() {
  }
  closeModal() {
    //this.modalController.dismiss();
    this.navCtrl.navigateForward(this.source);
  }
  
  gcvrupcidiSelect(){
    //console.log("this.gcvrpucidi"+this.gcvrpucidi); 
    if(this.gcvrpucidi==='GCVRPU'){
      this.gcvrpuind=true;
      this.cidivind=false;
    }
    if(this.gcvrpucidi==='CIDIV'){
      this.cidivind=true;
      this.gcvrpuind=false;
    }
  }
  gcvrpuoptionsselect(){
   //console.log(this.gcvrpuoptions);
  }
  gcvcidioptionsselect(){}
  kroCheck() {
    this.langOpt = this.dX684.getLan();
    if (this.langOpt === 'eng') {
      this.dLan = this.en.getQD();
      this.dL1 = this.en.getCinf();
    } else if (this.langOpt === 'frn') {
      this.dLan = this.fr.getQD();
      this.dL1 = this.fr.getCinf();
    }
  }
}
