import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EngLaService {
  prefLan;
  menuLan;
  landLan;
  cInfLan;
  homeLan;
  qCLan;
  qDLan;
  constructor() {
    this.setQD();
    this.setQC();
    this.setCinf();
    this.setPrf();
    this.setMenu();
    this.setLand();
    this.setHome();
  }
  getQD(){
    return this.qDLan;
  }
  setQD() {
    this.qDLan = {
      err:{
        p1:'Select a plan from ',
        p2:' Quote Compare',
        p3:' to see details',
		p5:'Select a product from ',
		p4:' Available Products',
        btn: 'Take me there'
      },
      hd:{
        p1:'Summary of ',
        p2:'for'
      },
      aged:'Age Nearest',
      agelast:'Age Last',
      rof: 'State:',
      pm:' / Month',
      table:{
        t1: 'Quote',
        t2: 'Rate Class',
        t3: 'Product Type'
      },
      wodRD:{
        p1: 'Would',
        p2: ' like to add one or more riders?',
        p3: ' like to get a copy of the quote by email'
      },
      btn: ' Recalculate Quote',
      em:'Email Address',
      txt: 'Mobile Number',
      calculate:' Calculate Quote',
      male:'Male',
      female:'Female'
    };
  }
  getQC(){
    return this.qCLan;
  }
  setQC(){
    this.qCLan = {
      err: {
        p1:'The following details are missing for',
        p2: ' Client Info:',
        btn: 'Back'
      },
      hd:'Plan Options for',
      insuranceneed:'Insurance Needs for',
      aged:'Age Nearest',
      agelast:'Age Last',
      rof: 'State:',
      male:'Male',
      female:'Female'
    };
  }
  setCinf() {
    this.cInfLan = {
      name:'Name',
      dob: 'Date of Birth',
      phone: 'Contact',
      state: 'State',
      agenearest:'Age Nearest',
      agelast:'Age Last',
      juverrormsg:'You are trying to add a duplicate record.',
      nameerrormsg: 'Please enter a name.',
      sex: {
        genderlabel: 'Gender',
        t1: 'Male',
        t2: 'Female'
      },
      rc: 'Rate Class',
      pt: {
        title: 'Product Type',
        t1: 'Medical',
        t2: 'Non-Medical'
      },
      solve: {
        title:'Solve For',
        t1: 'Face Amount',
        t2: 'Premium Amount',
        t5: 'Monthly',
        t6: 'Annual',
        t3: 'Premium Amount',
        t4: 'Face Amount',
        t7: 'Payment Frequency',
       },
      insuranceneeds: {
        q1:'Amount of savings?',
        q2:'Amount of investments?',
        q3:"Amount of existing life coverage?",
        q4:'How much is remaining on your mortgage?',
        q5:'How much do you owe in other debts, such as credit cards or loans?',
        q6:'How much do you want to put aside for major life events (including funeral, child education and major life events)?',
        q7:'What is your yearly income after tax?',
        q8:'What percentage of your income will your family need?',
        q9:'How many years will your family require financial support?',
        labelscreen1:'Your Assets',
        labelscreen2:'Your Expenses',
        labelscreen3:'Income For Your Family',
        calculatebutton:'Calculate',
        validateyourinformation: 'Validate your Information',
        yourpersonalinfo: 'Your personal information is:',
        getquotebutton: 'Calculate Quote',
        proceedbutton: 'Proceed with Options',
        productselection:'Go to Product Selection',
        editbutton: 'Edit',
        cancelbutton: 'Cancel',
        savebutton:'Save',
        company: 'Foresters Financial',
        insurancecalclabel:'Insurance Calculator',
        totalrequirement:'Total Life Insurance needed:',
        existingcoverage:'Existing Life Insurance coverage:',
        addnlrequirement:'Additional Life Insurance needed:',
        alertmsg: "If the information above is correct, click 'Calculate Quote'. <br\>Otherwise, click 'Edit' to modify your inputs, <br\>or 'Cancel' to stay on the current page."
      },
      eApp: {
        q1:'Social Security Number:',
        q2:'Address Line 1:',
        q3:"Address Line 2:",
        q4:'City:',
        q5:'State:',
        q6:'Zip Code:',
        q7:'Phone Number:',
        q8:'Best Time to Call:',
        q9:'Email:',
        labelscreen1:'Address and Contact:',
      },
      landingpage:{
        l1:'Welcome',
        l2:'Help',
        l3:'For App Assistance:',
        l4:'App Support Line:',
        l5:'Resources',
        l6: 'Click',
        l7: 'here',
        l8: 'to go to',
        l9:'Get Your Quote',
        l10:'Latest News',
        l11: 'service@truenorth.com',
        l12: 'http://www.Foresters.com',
        l13: 'Foresters.com',
        l14: 'Good Morning',
        l15: 'Good Afternoon',
        l16: 'Good Evening',
        l17: 'Foresters'
      },
      knowyourproductpage:{
        k1:'Know Your Products',
        k2:'Get Your Quote',
        k3:'Learn more about the products available.'
      },
      resourcespage:{
        r1: 'Resources',
        r2: 'Underwriting Guide (Term, UL & WL)',
        r3: 'Sales Material (PlanRight)',
        r4: 'Below are some useful reference guides for you.'
      },
      reiderslabel:{
        q1: 'Accidental Death:',
        q2: 'Child Term:',
        q3: 'Waiver of Premium:',
        q4: 'Guaranteed Insurability:',
        q5: 'Guaranteed Purchase Option:',
        q6: 'Waiver of Monthly Deduction:',
        q7: 'Term 10:',
        q8: 'Term 20:',
        q9: 'WPR:',
        q10:'Disability Income:',
        q11:'Return of Premium:',
        q12:'Table Rating:'
      },
      quotecomparepage:{
        p1: 'Advantage Plus II',
        p2: 'PlanRight',
        p3: 'SMART UL',
        p4: 'Your Term',
        p5: 'Strong Foundation',
        p6: 'BrightFuture',
        p7:'Prepared Accidental Death',
        pdefault: 'Term'
      },
      producttype:{
        med: 'Medical',
        nonmed:'Non-Medical',
        notapplicable:'NA'
      },
      pagetitle:{
        p1: 'Client Information',
        p2: 'Compare Plans',
        p3: 'Product Selection',
        p4: 'Plan Details',
        p5: 'Product Details',
        p6: 'Available Quotes',
        p7: 'Home',
        p8: "Insurance Needs",
        p9: "Personal Information",
        p10: "Juvenile Applicants",
        p11: "GCV RPU & Cost Indices",
        p12: "Juvenile Quote Details"
        //p13: "Administration"
      },
      amount:'Amount',
      monthlypremmode:'Monthly Premium',
      annualpremmode: 'Annual Premium',
      monthly:'Monthly',
      paymentmode:'',
      quarterly:'Quarterly',
      semiannuallry: 'Semi Annual',
      annually: 'Annual',
      selectaproduct: 'Select a Product',
      quotecompare: 'Quote Compare'
    }
  }
  getCinf() {
    return this.cInfLan;
  }
  setHome() {
    this.homeLan = {
      t1: 'Latest News',
      t2: 'Product Selection',
      t3: 'Customize Plan',
      t4: 'Login',
      t5: 'Insurance Needs',
      t6: 'Quote Compare',
      t7: 'Add Riders',
      t8: 'Add Applicants'
    };
  }
  getHome() {
    return this.homeLan;
  }
  setPrf() {
    this.prefLan = {
      title: 'Preferences',
      langLab: 'Language',
      Defaultpagelabel: 'Landing Page',
      Defaultpageopt1: 'Latest News',
      Defaultpageopt2: 'Client Info',
      Defaultpageopt3:'Product Selection',
      Defaultpageopt4:'A Specific Product',
      Productoption:'Select a Product',
      savepreference: 'Save Preferences',
      Defaultstatelabel: 'Preferred State',
      Prefcolorlabel:'Color Scheme',
      Quotepref :'Generate Special Quote',
      prefissueage: 'Default Issue Age',
      prefcoverage: 'Default Face Amount',
      PrefName:'Preferred Name',
      blue_theme:'Alternate color theme',
      black_theme:'Black',
      //purple_theme:'Purple',
      purple_theme:'Main color theme',
      green_theme:'Green',
      Quoteprefyes:'Yes',
      Quoteprefno: 'No'
    }
  }
  getPrf() {
    return this.prefLan;
  }
  setMenu() {
    this.menuLan = {
      t1: 'Latest News',
      t2: 'How Much Insurance',
      t3: 'Resources',
      t4: 'Know Your Products',
      t5: 'ezBiz',
      t6: 'Preferences',
      t7: 'Client Info',
      t8: 'Product Selection',
      t9: 'Quote Compare'
      //t10: "Administration"
    }
  }
  getMenu() {
    return this.menuLan;
  }
  getProductDescription(productname){
    if(productname==='Advantage Plus II'){
      return "This Whole Life product is for clients aged 0-80. Kindly refer to the product brochure for more details";
    }
    if(productname==='SMART UL'){
      return "This Universal Life product is for clients aged 0-80. Kindly refer to the product brochure for more details";
    }
    if(productname==='Your Term'){
      return 'This Term product is for clients aged 18-75. Kindly refer to the product brochure for more details';
    }
    if(productname==='PlanRight'){
      return 'This Whole Life product is for clients aged 50-80. State variations may apply. Kindly refer to the product brochure for more details';
    }
    if(productname==='Strong Foundation'){
      return 'This Basic Term product is for clients aged 18-75. State variations may apply. Kindly refer to the product brochure for more details';
    }
    if(productname==='BrightFuture'){
      return 'This Whole Life product is for clients from age 0-17. 6 applicants may apply under a single owner. Please refer to the product brochure for more details';
    }
    if(productname==='Prepared Accidental Death'){
      return 'This Term Life product is for clients from age 18-60. State variations may apply. Please refer to the product brochure for more details';
    }
  }
  setLand() {
    this.landLan = {
      title: 'Foresters Financial',
      msg: 'Some Messages....For..Agents..'
    };
  }
  getLand() {
    return this.landLan;
  }
}