import { Component, OnInit, ViewChild, } from '@angular/core';
import { NavController, PopoverController, LoadingController,ModalController, IonSlide } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../services/data.service';
import { EngLaService } from '../propertiesFile/eng-la.service';
import { FrnLaService } from '../propertiesFile/frn-la.service';
import { CurrencyPipe, JsonPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Storage} from '@ionic/storage';
import { AnalyticsService, AnalyticsEventNames } from './../propertiesFile/analytics.service';

@Component({
  selector: 'app-juvenile-quote-details',
  templateUrl: './juvenile-quote-details.page.html',
  styleUrls: ['./juvenile-quote-details.page.scss'],
})
export class JuvenileQuoteDetailsPage implements OnInit {
  langOpt;
  dLan;
  dL1;
  juvedetailsjson;
  px62;
  xd24;
  url;
  prod;
  channelcode;
  ownername;
  listofapplicants=[];
  Pay10Monthly =[];
  Pay10Annual =[];
  Pay100Monthly =[];
  Pay100Annual=[];
  Face10=[];
  Face100=[];
  Mec10Prem=[];
  Mec100Prem=[];
  Mec10Ind=[];
  Mec100Ind=[];
  MecMsg: any;
  MecRefLink: any;
  nm;
  sendemail;
  textchk=false;
  emailchk=false;
  email;
  textmsg;
  mediumaddress;
  medium;
  paymentmode;
  MecDisclaimerMsg;
  sngl: boolean;
  lst: boolean;
  @ViewChild('emailtr', { static: false }) emailinput;
  @ViewChild('textmsgtr', { static: false }) textmsginput;
  constructor(public loadingController: LoadingController,
    //private renderer : Renderer,
    private route: ActivatedRoute, 
    public currencyPipe: CurrencyPipe, 
    private navCtrl :NavController, 
    public dX684: DataService, 
    private en: EngLaService,
    private storage: Storage,
    private http: HttpClient,
    private mdlPkPl:ModalController,
    private analyticsService: AnalyticsService,
    private fr: FrnLaService) { 
    this.route.queryParams.subscribe(params => {
      this.kroCheck();
      //this.url=this.dX684.getJuve();
      this.url=this.dX684.getJuveEncrypted();
      this.juvedetailsjson=[];
      this.juvedetailsjson=this.dX684.getJuvenileData();
      this.ownername=this.juvedetailsjson.ownername;
      this.xd24=this.dX684.getData();
      this.nm=this.dX684.getQdD().name;
      this.sendemail=this.dX684.sendEmailIndicator(this.nm);
      this.paymentmode = this.dX684.getPayeeType(this.xd24.freq,this.dL1)
      this.listofapplicants=[];
      this.Pay10Monthly =[];
      this.Pay10Annual =[];
      this.Pay100Monthly =[];
      this.Pay100Annual=[];
      this.Face10=[];
      this.Face100=[];
      this.Mec10Prem=[];
      this.Mec100Prem=[];
      this.Mec10Ind=[];
      this.Mec100Ind=[];
      for(let i=0; i<this.juvedetailsjson.cNameList.length; i++){
        this.listofapplicants.push(i);
      }
      this.viewT('lst');
      this.getJuveData()
    });
  }
  ngOnInit() {
  }
  kroCheck() {
    this.langOpt = this.dX684.getLan();
    if (this.langOpt === 'eng') {
      this.dLan = this.en.getQD();
      this.dL1 = this.en.getCinf();
    } else if (this.langOpt === 'frn') {
      this.dLan = this.fr.getQD();
      this.dL1 = this.fr.getCinf();
    }
  }
  goLanding() {
    this.navCtrl.navigateRoot('landing');
    //this.closeModal();
  }
  closeModal() {
    this.navCtrl.navigateRoot('juvenile-input-screen');
  }
  focus(field)
  {
    if(this.emailchk===true)
    {
      if(field==='email'){
        setTimeout(() => {
          this.emailinput.setFocus();
        }, 250);
      }
    }
    if(this.textchk===true)
    {
      if(field==='text'){
        setTimeout(() => {
          this.textmsginput.setFocus();
        }, 250);
      }
    }
  }
  async openReport(slno,name,age,gen){
    const loading = await this.loadingController.create({
      message: 'Generating Report',
      duration: 15000
    });
    await loading.present();
    const ul = this.dX684.getJuvViewReport();
    var qrystring='';
    qrystring += '$$OpusPair$$channel=$$OpusVar$$'+this.channelcode;
    qrystring += '$$OpusPair$$' + 'subchannel=' + '$$OpusVar$$' +this.xd24.subchannelcode;
    qrystring += '$$OpusPair$$productType=$$OpusVar$$'+this.xd24.prodTyp;
    qrystring += '$$OpusPair$$productName=$$OpusVar$$'+this.nm;
    qrystring += '$$OpusPair$$State=$$OpusVar$$'+this.xd24.stateValue;
    qrystring += '$$OpusPair$$Frequency=$$OpusVar$$'+this.xd24.freq;
    if(this.dX684.getagentName()=='' ||this.dX684.getagentName()==undefined){
      qrystring+='$$OpusPair$$' + 'agentName=' + '$$OpusVar$$' + 'Sample Agent';
    }else{
      qrystring+='$$OpusPair$$' + 'agentName=' + '$$OpusVar$$' + this.dX684.getagentName();
    }
    qrystring += '$$OpusArr$$=[';
    qrystring += '[$$OpusPair$$Age='+'$$OpusArrValue$$'+age+']';
    qrystring += '[$$OpusPair$$Gender='+'$$OpusArrValue$$'+gen+']';
    if(this.xd24.solve === "prem"){
      qrystring += '[$$OpusPair$$SolveAmount='
    } 
    else if(this.xd24.solve === "face"){
      qrystring += '[$$OpusPair$$PremiumAmount='
    }
    qrystring += '$$OpusArrValue$$'+this.juvedetailsjson.solveAmountList[slno]
    qrystring += ']';
    qrystring += '[$$OpusPair$$Name='+'$$OpusArrValue$$'+name;
    qrystring += ']]';
    //console.log(ul +qrystring);
    //console.log(ul +this.dX684.Encrypt(qrystring));
    this.http.get(ul +this.dX684.Encrypt(qrystring)).subscribe(data => {
      //this.dX684.DecryptReturnVal(data);
      //console.log(this.dX684.DecryptReturnVal(data));
      loading.dismiss();
      loading.onDidDismiss().then(e => {
        if(this.prod){
          this.dX684.openURL(this.dX684.DecryptReturnVal(data)[0]);
          this.dX684.presentToastWithOptions('', 'Report Loaded');
        }
        else{
          this.dX684.presentToastWithOptions('','Cannot load the Report. Please try later');
        }
      });
    });
  }
  openGCVRPUCIDIPopover(value, rowid, insured){
    //console.log("this.nm:"+this.nm+"value:"+value+"rowid:"+rowid+"insured:"+insured);
    const dXT62 = {
      nm: this.nm,
      value:value,
      rowid:rowid,
      insured:insured,
      ownername:this.ownername,
      px:this.prod.types[0].subtype[0]
    };
    this.dX684.setOpenGCVRPUCIDIPopover(dXT62);
    if(value === "GCVRPU") this.analyticsService.logEvent(AnalyticsEventNames.gcvRUPJuvenile, {product: this.nm, page_name: "Juvenile Applicants"});
    if(value === "CIDIV") this.analyticsService.logEvent(AnalyticsEventNames.costIndicesJuvenile, {product: this.nm, page_name: "Juvenile Applicants"});
    this.navCtrl.navigateForward('juvenile-client-popover');
  }
  async em(medium) {
    const loading = await this.loadingController.create({
      message: 'Sending ' + medium,
      duration: 15000
    });
    await loading.present();
    this.medium=medium;
    if(this.medium==='Text'){
      this.mediumaddress = String(this.textmsg);
      this.mediumaddress=this.mediumaddress.replace('+','');
      this.mediumaddress=this.mediumaddress.replace('(','');
      this.mediumaddress=this.mediumaddress.replace(')','');
      this.mediumaddress = Number(this.mediumaddress);
    }
    if(this.medium==='Email'){
      this.mediumaddress = this.email;
    }
    if(this.mediumaddress)
    {
      let re=/\, /gi;
      this.mediumaddress=this.mediumaddress.replace(re,' ');
      re = /\ /gi;
      this.mediumaddress=this.mediumaddress.replace(re,',');
    }
    if(!this.dX684.isEmail(this.mediumaddress, medium)){
      this.dX684.presentToastWithOptions('',medium+' not sent! Please check your '+medium);
      loading.dismiss();
      if(medium==='email')
        this.emailinput.setFocus();
      if(medium==='text')
        this.textmsginput.setFocus();
    }
    else{
      //const ul = this.dX684.getJuvEm();
      const ul = this.dX684.getJuvEmEncrypted();
      this.storage.get('channel').then ( (val)=>{
        this.channelcode = val;
        //let temp = this.medium + '=' + this.mediumaddress;
        let temp = '$$OpusPair$$' + this.medium + '=$$OpusVar$$' + this.mediumaddress;
        if(this.dX684.getagentName()=='' ||this.dX684.getagentName()==undefined){
          temp+='$$OpusPair$$' + 'agentName=' + '$$OpusVar$$' + 'Sample Agent';
        }else{
          temp+='$$OpusPair$$' + 'agentName=' + '$$OpusVar$$' + this.dX684.getagentName();
        }
        //temp += '$$OpusPair$$channel=$$OpusVar$$'+ this.channelcode;
        //console.log(ul + temp+ this.px62);
        //console.log(ul + this.dX684.Encrypt(temp+ this.px62));
        if (this.px62) {
          if (this.mediumaddress) {
            //this.http.get(ul + temp+ this.px62+'&Name='+this.juvedetailsjson.cNameList)
            this.http.get(ul + this.dX684.Encrypt(temp+ this.px62)).subscribe(data => {
              //console.log(this.dX684.DecryptReturnVal(data));
              if (this.dX684.DecryptReturnVal(data) === true) {
                //console.log("inside dismiss loop");
                loading.dismiss();
                loading.onDidDismiss().then(e => {
                  this.analyticsService.logEvent(AnalyticsEventNames.sendEmailJuvenile,  {page_name: "Juvenile Quote Details", product_name:"BrightFuture"});
                  this.dX684.presentToastWithOptions('',medium+' sent!');
                });
              }
              else{
                loading.dismiss();
                loading.onDidDismiss().then(e => {
                  this.dX684.presentToastWithOptions('',medium+' not sent! Server Error');
                });
              }
            });
          }
          else {
            this.dX684.presentToastWithOptions('',medium+' not sent! Please check your inputs');
          }
        }
      });
    }
  }
  handleEnter(e, medium) {
    //this.renderer.invokeElementMethod(e.target, 'blur');
    e.target.blur();
    this.em(medium);
  }
  openURL(url){
    this.analyticsService.logEvent(AnalyticsEventNames.checkMEC,  {page_name: "Juvenile Quote Details", product_name:"BrightFuture"});
    this.dX684.openURL(url);
  }
  async getJuveData(){
    const loading = await this.loadingController.create({
      message: 'Loading',
      duration: 1500,
    });
    await loading.present();
    this.storage.get('channel').then((val) => {
      this.channelcode = val;
      this.px62 = '';
      this.px62 += '$$OpusPair$$channel=$$OpusVar$$'+this.channelcode;
      this.px62 += '$$OpusPair$$' + 'subchannel=' + '$$OpusVar$$' +this.xd24.subchannelcode;
      this.px62 += '$$OpusPair$$productType=$$OpusVar$$'+this.xd24.prodTyp;
      this.px62 += '$$OpusPair$$productName=$$OpusVar$$'+this.nm;
      this.px62 += '$$OpusPair$$State=$$OpusVar$$'+this.xd24.stateValue;
      this.px62 += '$$OpusPair$$Frequency=$$OpusVar$$'+this.xd24.freq;
      this.px62 += '$$OpusArr$$=[';
      this.px62 += '[$$OpusPair$$Age=';
      for(let i=0;i<this.juvedetailsjson.ageList.length;i++){
        this.px62 += '$$OpusArrValue$$'+this.juvedetailsjson.ageList[i]
      }
      this.px62 += ']';
      this.px62 += '[$$OpusPair$$Gender=';
      for(let i=0;i<this.juvedetailsjson.genValList.length;i++){
        this.px62 += '$$OpusArrValue$$'+this.juvedetailsjson.genValList[i]
      }
      this.px62 += ']';
      if(this.xd24.solve === "prem"){
        this.px62 += '[$$OpusPair$$SolveAmount='
        } 
        else if(this.xd24.solve === "face"){
          this.px62 += '[$$OpusPair$$PremiumAmount='
        }
      //this.px62 += '[$$OpusPair$$'+this.dX684.dTur_DX09Slv(this.xd24)+'=';
      for(let i=0;i<this.juvedetailsjson.solveAmountList.length;i++){
        this.px62 += '$$OpusArrValue$$'+this.juvedetailsjson.solveAmountList[i]
      }
      this.px62 += ']';
      this.px62 += '[$$OpusPair$$Name=';
      for(let i=0;i<this.juvedetailsjson.cNameList.length;i++){
        this.px62 += '$$OpusArrValue$$'+this.juvedetailsjson.cNameList[i]
      }
      this.px62 += ']]';
      //console.log(this.url + this.px62);
      this.http.get(this.url +this.dX684.Encrypt(this.px62)).subscribe(data => {
        this.prod = this.dX684.DecryptReturnVal(data);
        //console.log(this.prod);
        loading.dismiss();
        loading.onDidDismiss().then(e => {
          if(this.prod)
            this.dX684.presentToastWithOptions('', 'Loaded');
          else{
            this.dX684.presentToastWithOptions('','Cannot load the data. Please try later');
          }
        });
        for(let i=0; i<this.listofapplicants.length; i++){
          this.Pay10Monthly.push(this.prod.types[0].subtype[0].doubleArray[2].DArray[i]);
          this.Pay10Annual.push(this.prod.types[0].subtype[0].doubleArray[3].DArray[i]);
          this.Pay100Monthly.push(this.prod.types[0].subtype[0].doubleArray[0].DArray[i]);
          this.Pay100Annual.push(this.prod.types[0].subtype[0].doubleArray[1].DArray[i]);
          this.Face10.push(this.prod.types[0].subtype[0].doubleArray[5].DArray[i]);
          this.Face100.push(this.prod.types[0].subtype[0].doubleArray[4].DArray[i]);
          this.Mec100Prem.push(this.prod.types[0].subtype[0].doubleArray[42].DArray[i]);
          this.Mec10Prem.push(this.prod.types[0].subtype[0].doubleArray[44].DArray[i]);
          if(this.prod.types[0].subtype[0].doubleArray[45].DArray[i]===1){
            this.Mec10Ind.push('ß');
            this.MecMsg=this.prod.types[0].subtype[0].stringArray[2].SArray[0];
            this.MecRefLink = this.prod.types[0].subtype[0].stringArray[3].SArray[0];
          }
          else
            this.Mec10Ind.push(' ');
          if(this.prod.types[0].subtype[0].doubleArray[43].DArray[i]===1){
            this.Mec100Ind.push('ß');
            this.MecMsg=this.prod.types[0].subtype[0].stringArray[2].SArray[0];
            this.MecRefLink = this.prod.types[0].subtype[0].stringArray[3].SArray[0];
          }
          else
            this.Mec100Ind.push(' ');
          //console.log(this.Mec100Ind);
        }
        if(this.MecMsg){
          this.MecMsg="<sup>ß</sup>&nbsp;"+this.MecMsg+"<br>"
          //console.log(this.MecMsg);
        }
        this.MecDisclaimerMsg=this.prod.types[0].subtype[0].stringArray[4].SArray[0];
      });
    });
  }
  viewT(rd) {
    if (rd === 'lst') {
      this.sngl = false;
      this.lst = true;
    } else if (rd === 'sngl') {
      this.lst = false;
      this.sngl = true;
    }
  }
  prodSelect(){
    this.navCtrl.navigateForward('quote-compare-only-product');
  }
}