import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-product-type-popover',
  templateUrl: './product-type-popover.page.html',
  styleUrls: ['./product-type-popover.page.scss'],
})
export class ProductTypePopoverPage implements OnInit {

  constructor(
    private navParams: NavParams,
    private popoverController: PopoverController,
    private dataService: DataService) {
      this.productTypeList = this.dataService.getDataToProductTypePopover();
  }
  productTypeList;
  selectedOptionProductType;
  ngOnInit() {
  }
  doSomething(ev) {
    this.dataService.setSelectedProductType(this.selectedOptionProductType);
    this.popoverController.dismiss();
   }
}
