import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-rate-class-popover',
  templateUrl: './rate-class-popover.page.html',
  styleUrls: ['./rate-class-popover.page.scss'],
})
export class RateClassPopoverPage implements OnInit {
  sele;
  constructor(
    private navParams: NavParams,
    private route: ActivatedRoute,
    private popoverController: PopoverController,
    private dataService: DataService) {
      this.route.queryParams.subscribe(params => {
      const y = this.dataService.getDataToRateClassPopover();
	    //console.log("const y:"+y);
      this.sele = this.dataService.getQdD();
      //console.log("this.sele:"+this.sele);
	    if(this.sele.name)
      {
        this.sele = this.sele.name;
      }
      //console.log("this.sele:"+this.sele);
      //this.rateClassList = this.dataService.rate(this.sele);
      var age= this.dataService.calcAge(this.dataService.getData().dob);
      this.rateClassList =this.dataService.GetRateClassDetails1(this.dataService.getData().prodTyp,age, this.sele);
      
	    //console.log("this.rateClassList"+this.rateClassList);
    });
  }
  rateClassList = [];
  selectedOptionRateClass;
  ngOnInit() {
  }

  doSomething(ev) { 
   //console.log("line 41:"+this.selectedOptionRateClass);
   this.dataService.setSelectedRateClass(this.selectedOptionRateClass);
   this.popoverController.dismiss();
  }


}
