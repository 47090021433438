import { Component, NgZone ,ElementRef} from '@angular/core';
//import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Platform, NavController, AlertController, Events } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { DataService } from './services/data.service';
import { EngLaService } from './propertiesFile/eng-la.service';
import { FrnLaService } from './propertiesFile/frn-la.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Network } from '@ionic-native/network/ngx';
import { PushObject } from '@ionic-native/push';
import { HttpClient } from '@angular/common/http';
import { Storage} from '@ionic/storage';
//import * as $ from "jquery";
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Idle } from 'idlejs/dist';
//import {Keepalive} from '@ng-idle/keepalive';
//import {EventTargetInterruptSource, Idle} from '@ng-idle/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  // tslint:disable-next-line: max-line-length
  //public eBiz = 'https://play.google.com/store/apps/details?id=com.infusion.ForestersProducerPortalMobile&rdid=com.infusion.ForestersProducerPortalMobile&feature=md&offerId';
  //public eBiz = "itms-apps://itunes.apple.com/us/app/foresters-biz/id602063944?mt=8";
  public eBiz = '';
  public appPages;
  dataLan;
  status = 'ONLINE';
  isConnected = true;
  activationcode;
  selectedTheme: String;
  timedOut = false;
  currentyear;
  min:any;
  sec;any;
  title = 'Session Timeout Demo';
  idleState = 'NOT_STARTED';
  lastPing?: Date = null;
  data;
  Name;
  public pushObject: PushObject = null;
  constructor(
    private platform: Platform,
    private keyboard: Keyboard,
    private element: ElementRef,
    private router: Router,
    private network: Network,
    private route: ActivatedRoute,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public dt: DataService,
    private navCtrl: NavController,
    public en: EngLaService,
    public alertController:AlertController,
    public fr: FrnLaService,
    public event: Events,
    public http: HttpClient,
    public ngZone: NgZone,
    private storage: Storage,) {
      this.route.queryParams.subscribe(params => {
        this.network.onDisconnect().subscribe(async() => {
        });
      this.checkKaro();
    });
    this.initializeApp();
    platform.ready().then(() => {
      statusBar.styleDefault();
      if ( this.route.snapshot['_routerState'].url !== '/login') {
        this.navCtrl.navigateRoot('/login')
          .then();
          this.storage.get("activation code").then(val => {
            this.dt.setFingerPrintInd("Y");
          });
      }
      const idle = new Idle()
      .whenNotInteractive()
      .within(120)
      .do(() =>  {
        //alert('testdata')
        this.data = this.dt.getData(); 
        this.data='';
        this.dt.setData(this.data);
        this.dt.validateactivationcode(); 
        this.dt.setFingerPrintInd("Y");
        this.navCtrl.navigateRoot('login');
      }).start();
      //console.log("idle:"+idle);
      
      /* if (platform.is('android')) {
          statusBar.hide();
          statusBar.overlaysWebView(false);
          statusBar.backgroundColorByHexString('#000000');
          //keyboard.hideFormAccessoryBar(false);
          this.keyboard.onKeyboardShow().subscribe((e) => {
            const safeArea = 40 ;
            const offset1 = $(document.activeElement).offset().top;
            const offset2 = window.innerHeight - e.keyboardHeight - $(document.activeElement).height() - safeArea ;
            const diff = offset1 - offset2;
            if(offset1 > window.innerHeight -  e.keyboardHeight - safeArea)
              $('body').animate({ 'marginTop': -1 * diff + 'px' }, 100);
          });
          this.keyboard.onKeyboardHide().subscribe(e => {
            $('body').animate({ 'marginTop': 0 + 'px' }, 100);
          });
      }
      if (platform.is('ios')) {
        statusBar.hide();
        statusBar.overlaysWebView(false);
        statusBar.backgroundColorByHexString('#000000');
        keyboard.hideFormAccessoryBar(false);
      } */
  });
  
}

  initializeApp() {
    this.platform.ready().then(() => {
      //this.registerPushNotification();
      this.platform.backButton.subscribeWithPriority(1, () => { // to disable hardware back button on whole app
      });
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      var scope = this;
    });
  }
  pref() {
    this.navCtrl.navigateForward('/pref')
  }
  testing() {
    this.navCtrl.navigateForward('temp-direct');
  }
  setmodeentry(url){
    //console.log("inside:"+url)
    if(url ==='quote-compare-only-product'){
      this.dt.setModeEntery('sprouter',url);
    }
    if(url==='client-basic-input'){
      this.dt.setModeEntery('qc',url);
      this.dt.setRouterTabStyle('tabunselectcolor');
    }
  }
  checkKaro(){
    //console.log("inside Checkkro")
    var currentdate = new Date()
    this.currentyear = currentdate.getFullYear();
    this.eBiz=this.dt.getDownloadLink();  
    this.dt.setTheme();
      this.route.queryParams.subscribe(params => {
        if(this.dt.getLan() === 'eng' ) {
          this.dataLan = this.en.getMenu();
        } else if(this.dt.getLan() === 'frn' ) {
          this.dataLan = this.fr.getMenu();      
        }
        this.appPages= [
          {
            title: this.dataLan.t1,
            url: 'landing',
            icon: 'home'
          },
          {
            //title: 'Get Quote',
            title: this.dataLan.t7,
            url: 'client-basic-input',
            icon: 'person'
          },
          {
            //title: 'Get Quote',
            title: this.dataLan.t8,
            url: 'quote-compare-only-product',
            icon: 'menu'
          },
          {
            //title: 'Get Quote',
            title: this.dataLan.t9,
            url: 'quote-compare',
            icon: 'git-compare'
          },
          {
            //title: 'Get Quote',
            title: this.dataLan.t2,
            url: 'how-much-insurance',
            icon: 'umbrella'
          },
         /* {
            //title: 'Get Quote',
            title: "Insurance Needs",
            url: 'needs-analysis',
            icon: 'umbrella'
          },*/
          {
            title: this.dataLan.t3,
            url: 'resour',
            icon: 'document'
          },
          {
            title: this.dataLan.t4,
            url: 'know-your-product',
            icon: 'information-circle-outline'
          },
          
        ];
        //console.log("New Name:"+this.dt.getnewName());
        if(this.dt.getnewName()!=null){
          this.Name=this.dt.getnewName();
          this.dt.setNewname(null);
          this.dt.setagentName(this.Name);
        }
        else{
          this.storage.get('Name').then ( (val)=>{
            this.Name= val;
            this.dt.setagentName(this.Name);
            //console.log(this.Name);
          })
        }
    });
  }
  openURL(url){
    this.dt.openURL(url);
  }
  LogOut(){
    //console.log("inside logout")
    this.dt.setNewname(null);
    //console.log("inside logout get newName:"+ this.dt.getnewName());
    this.Name=null;
    this.dt.setagentName(null);
    this.dt.LogOut();
    this.navCtrl.navigateForward('/login');
  }
}
