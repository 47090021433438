import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JuvenileAgeService {
  range;
  constructor() { }
  private age = 17;
  private minage = 0;
  getJA() {
    return this.age;
  }
  getminJA() {
    return this.minage;
  }
  getFace56(){
    var val={
      age:56,
      maxcoverage:150000
    }
    return val;
  }
  getJuvNonBrightFaceRange(){
    return 150000;
  }
  getFaceRange(){
    var range={
      minFace:5000,
      maxFace:75000
    }
    return range;
  }
  RangeValidation(number, solvetype){
    var range=this.getFaceRange();
    //console.log("solvetype:"+solvetype+" number:"+number+" this.range.minFace:"+range.minFace+" range.maxFace:"+range.maxFace);
    if(solvetype === 'prem'){
      if(Number(number)<Number(range.minFace) || Number(number)>Number(range.maxFace)){
        return false;
      }
    }
    return true;
  }
}
